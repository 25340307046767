import axios from "axios";
import { justxUtilityURL } from "../config";

async function makeRequest(
  method: "GET" | "POST",
  url: string,
  requestBody: Object = {},
  params: Record<string, string> = {}
) {
  try {
    const headers: Record<string, string> = {};

    const response = await axios({
      method,
      url,
      headers,
      data: method === "POST" ? requestBody : {},
      params,
    });

    if ("result" in response.data && response.data.result === "success") {
      return [response.data.data, null];
    } else {
      return [response.data, null];
    }
  } catch (error: any) {
    if (error.response) {
      // Server responded with a status other than 2xx
      const errorMessage =
        error.response.data?.data?.message ||
        error.response.data?.data?.messaage ||
        "Server error occurred";
      return [null, new Error(errorMessage)];
    } else if (error.request) {
      // Request was made but no response received
      return [null, new Error("No response received from server")];
    } else {
      // Something else happened while setting up the request
      return [null, new Error("Error setting up request: " + error.message)];
    }
  }
}

export const GetTokenForWeb3AuthTelegramLogin = async (authData: Object) => {
  const url = `${justxUtilityURL}/api/auth/telegram/login`;
  const requestBody = {
    auth_data: authData,
    is_mini_app: false,
  };
  return await makeRequest("POST", url, requestBody);
};
