// src/components/ProfilePicture.tsx

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

interface ProfilePictureProps {
  src: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src }) => {
  const { colors } = useTheme();

  return (
    <div 
      className="w-24 h-24 rounded-full overflow-hidden border border-theme-border"
      style={{ backgroundColor: colors.accent,}}
    >
      {src ? (
        <img src={src} alt="Profile" className="w-full h-full object-cover" />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-4xl" style={{ color: colors.bg }}>
          ?
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;