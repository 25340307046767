// handleBlockchainLearningAgent.ts

export const handleBlockchainLearningAgent = async (input: string, chatId: string | null) => {
    try {
    //   const url = "https://agentic-learning-backend.justx.ai/learningagent";
      const url = "https://agentic-learning-backend.justx.ai/proxy/learningagent";
      const payload = {
        messages: [
          {
            role: "user",
            content: input
          }
        ],
        chatId: chatId || "1009" // Use the provided chat ID or a default value
      };
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Blockchain Learning Agent Error:", error);
      throw error;
    }
  };