import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiSearch, FiX, FiPlus, FiCheck, FiStar, FiDownload } from 'react-icons/fi';
import coins from "../../Assets/Image/download-removebg-preview.png";
import blockchainIcon from "../../Assets/Image/mnemonicgeneration.gif"
// import solana from '../../Assets/Image/solana-logo.png';
// import comdex from '../../Assets/Image/comdex.png';
import market from "../../Assets/Image/marketplace.png";
import solana from '../../Assets/Image/solana.svg';
import solanaLearningIcon from '../../Assets/Image/solana-learning-icon.gif'; 
interface Review {
  id: string;
  user: string;
  rating: number;
  comment: string;
  date: string;
}

interface Agent {
  id: string;
  name: string;
  type: string;
  action: string;
  icon: string;
  description: string;
  longDescription: string;
  version: string;
  developer: string;
  downloadCount: number;
  rating: number;
  reviews: Review[];
  categories: string[];
  releaseDate: string;
  lastUpdate: string;
  size: string;
  compatibility: string[];
  features: string[];
}

interface MarketplaceProps {
  onSelectAgent: (agent: Agent) => void;
  AllConnectAgents: Agent[];
}

const apps: Agent[] = [
 
  {
    id: '3',
    name: 'Solana',
    type: 'Blockchain',
    action: 'Add',
    icon: solana,
    description: 'Solana is a high-performance blockchain supporting builders around the world creating crypto apps that scale.',
    longDescription: 'Solana is a decentralized blockchain built to enable scalable, user-friendly apps for the world. Its known for its fast transaction speeds and low costs, making it an ideal platform for decentralized applications (dApps) and cryptocurrencies.',
    version: '1.0.0',
    developer: 'Solana Foundation',
    downloadCount: 100000,
    rating: 4.8,
    reviews: [
      { id: '1', user: 'DeFi Developer', rating: 5, comment: 'Incredibly fast and cost-effective for DeFi applications!', date: '2023-07-15' },
      { id: '2', user: 'Crypto Enthusiast', rating: 4, comment: 'Great potential, but still needs more adoption.', date: '2023-07-10' },
    ],
    categories: ['Blockchain', 'DeFi', 'Smart Contracts','Ai'],
    releaseDate: '2020-03-16',
    lastUpdate: '2023-07-01',
    size: '15MB',
    compatibility: ['Windows', 'MacOS', 'Linux'],
    features: ['Fast transactions', 'Low fees', 'Smart contracts', 'Proof of History'],
  },
  {
    id: '4',
    name: 'Blockchain',
    type: 'Learning',
    action: 'Add',
    icon: blockchainIcon,
    description: 'Learn about blockchain technology, its fundamentals, and applications.',
    longDescription: 'Explore the world of blockchain technology, from basic concepts to advanced applications. This agent provides comprehensive information about blockchain fundamentals, cryptography, smart contracts, and various use cases.',
    version: '1.0.0',
    developer: 'JustX AI',
    downloadCount: 75000,
    rating: 4.7,
    reviews: [
      { id: '1', user: 'Blockchain Enthusiast', rating: 5, comment: 'Excellent resource for learning blockchain concepts!', date: '2023-07-20' },
      { id: '2', user: 'Crypto Newbie', rating: 4, comment: 'Great for beginners, but could use more advanced topics.', date: '2023-07-18' },
    ],
    categories: ['Blockchain', 'Education', 'Cryptocurrency', 'AI'],
    releaseDate: '2023-06-01',
    lastUpdate: '2023-07-15',
    size: '10MB',
    compatibility: ['Web', 'Mobile'],
    features: ['Blockchain fundamentals', 'Cryptography basics', 'Smart contract explanations', 'Use case exploration'],
  },
  {
    id: '5',
    name: 'Solana Learning',
    type: 'Learning',
    action: 'Add',
    icon: solanaLearningIcon,
    description: 'Learn about Solana blockchain, its ecosystem, and development on Solana through interactive conversations.',
    longDescription: 'The Solana Learning agent provides in-depth knowledge about the Solana blockchain, its architecture, ecosystem, and development practices. It\'s an interactive learning tool for developers, investors, and crypto enthusiasts interested in Solana.',
    version: '1.0.0',
    developer: 'JustX AI',
    downloadCount: 50000,
    rating: 4.7,
    reviews: [
      { id: '1', user: 'Solana Developer', rating: 5, comment: 'Excellent resource for learning Solana development!', date: '2023-08-01' },
      { id: '2', user: 'Blockchain Student', rating: 4, comment: 'Very informative, but could use more practical examples.', date: '2023-07-28' },
    ],
    categories: ['Blockchain', 'Education', 'Solana', 'AI'],
    releaseDate: '2023-06-15',
    lastUpdate: '2023-07-30',
    size: '12MB',
    compatibility: ['Web', 'Mobile'],
    features: ['Solana fundamentals', 'Ecosystem overview', 'Development guides', 'Interactive Q&A'],
  },
];

const categories = ['All', 'Blockchain', 'AI', 'Productivity', 'Development', 'Decentralized'];

const Marketplace: React.FC<MarketplaceProps> = ({ onSelectAgent, AllConnectAgents }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredApps, setFilteredApps] = useState(apps);
  const [selectedApp, setSelectedApp] = useState<Agent | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    const filtered = apps.filter(app => 
      (app.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.type.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedCategory === 'All' || app.categories.includes(selectedCategory))
    );
    setFilteredApps(filtered);
  }, [searchTerm, selectedCategory]);

  const isAgentSelected = (agent: Agent) => {
    return AllConnectAgents.some(selected => selected.id === agent.id);
  };

  const handleAppClick = (app: Agent) => {
    setSelectedApp(app);
  };

  const closeAppDetails = () => {
    setSelectedApp(null);
  };

  const renderStars = (rating: number) => {
    return Array(5).fill(0).map((_, i) => (
      <FiStar key={i} className={`inline ${i < Math.round(rating) ? 'text-yellow-400' : 'text-gray-400'}`} />
    ));
  };

  const renderAppCard = (app: Agent) => (
    <motion.div
      key={app.id}
      layout
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      className="bg-white bg-opacity-10 rounded-xl p-6 hover:bg-opacity-20 transition-all cursor-pointer shadow-inner border-[1px] border-gray-100"
      onClick={() => handleAppClick(app)}
    >
      <div className="flex items-center mb-4">
        <img src={app.icon} alt={app.name} className="w-16 h-16 mr-4 rounded-lg" />
        <div>
          <h3 className="text-xl font-semibold text-black">{app.name}</h3>
          <p className="text-purple-300">{app.type}</p>
        </div>
      </div>
      <p className="text-gray-300 mb-4 line-clamp-2">{app.description}</p>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          {renderStars(app.rating)}
          <span className="ml-2 text-sm text-gray-300">({app.rating.toFixed(1)})</span>
        </div>
        <div className="flex items-center text-gray-300">
          <FiDownload className="mr-1" />
          <span className="text-sm">{app.downloadCount.toLocaleString()}</span>
        </div>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`px-4 py-2 rounded-lg flex items-center justify-center w-full ${
          isAgentSelected(app) ? 'bg-green-500 text-white' : 'bg-purple-500 hover:bg-purple-600 text-white'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          onSelectAgent(app);
        }}
        disabled={app.action === 'Coming Soon'}
      >
        {isAgentSelected(app) ? (
          <>
            <FiCheck className="mr-2" /> Added
          </>
        ) : app.action === 'Coming Soon' ? (
          'Coming Soon'
        ) : (
          <>
            <FiPlus className="mr-2" /> Add
          </>
        )}
      </motion.button>
    </motion.div>
  );

  // const renderDetailedView = (app: Agent) => (
  //   <motion.div
  //     initial={{ opacity: 0 }}
  //     animate={{ opacity: 1 }}
  //     exit={{ opacity: 0 }}
  //     className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
  //     onClick={closeAppDetails}
  //   >
  //     <motion.div
  //       initial={{ scale: 0.9, y: 20 }}
  //       animate={{ scale: 1, y: 0 }}
  //       exit={{ scale: 0.9, y: 20 }}
  //       className="bg-white rounded-xl p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto text-gray-800"
  //       onClick={(e) => e.stopPropagation()}
  //     >
  //       <div className="flex justify-between items-start mb-6">
  //         <div className="flex items-center">
  //           <img src={app.icon} alt={app.name} className="w-20 h-20 mr-4 rounded-lg" />
  //           <div>
  //             <h2 className="text-2xl font-bold">{app.name}</h2>
  //             <p className="text-purple-600">{app.type}</p>
  //             <div className="flex items-center mt-2">
  //               {renderStars(app.rating)}
  //               <span className="ml-2 text-sm text-gray-500">({app.rating.toFixed(1)})</span>
  //             </div>
  //           </div>
  //         </div>
  //         <button onClick={closeAppDetails} className="text-gray-500 hover:text-gray-700">
  //           <FiX size={24} />
  //         </button>
  //       </div>
  //       <div className="grid grid-cols-2 gap-6 mb-6">
  //         <div>
  //           <h3 className="text-lg font-semibold mb-2">About</h3>
  //           <p className="text-gray-600 mb-4">{app.longDescription}</p>
  //           <div className="flex flex-wrap gap-2 mb-4">
  //             {app.categories.map((category, index) => (
  //               <span key={index} className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-sm">
  //                 {category}
  //               </span>
  //             ))}
  //           </div>
  //         </div>
  //         <div>
  //           <h3 className="text-lg font-semibold mb-2">Details</h3>
  //           <ul className="space-y-2 text-sm">
  //             <li><strong>Version:</strong> {app.version}</li>
  //             <li><strong>Developer:</strong> {app.developer}</li>
  //             <li><strong>Downloads:</strong> {app.downloadCount.toLocaleString()}</li>
  //             <li><strong>Release Date:</strong> {app.releaseDate}</li>
  //             <li><strong>Last Update:</strong> {app.lastUpdate}</li>
  //             <li><strong>Size:</strong> {app.size}</li>
  //             <li><strong>Compatibility:</strong> {app.compatibility.join(', ')}</li>
  //           </ul>
  //         </div>
  //       </div>
  //       <div className="mb-6">
  //         <h3 className="text-lg font-semibold mb-2">Features</h3>
  //         <ul className="list-disc list-inside space-y-1">
  //           {app.features.map((feature, index) => (
  //             <li key={index}>{feature}</li>
  //           ))}
  //         </ul>
  //       </div>
  //       <div className="mb-6">
  //         <h3 className="text-lg font-semibold mb-2">Reviews</h3>
  //         {app.reviews.length > 0 ? (
  //           <div className="space-y-4">
  //             {app.reviews.map((review) => (
  //               <div key={review.id} className="border-b pb-4">
  //                 <div className="flex justify-between items-center mb-2">
  //                   <span className="font-semibold">{review.user}</span>
  //                   <span className="text-sm text-gray-500">{review.date}</span>
  //                 </div>
  //                 <div className="flex items-center mb-2">
  //                   {renderStars(review.rating)}
  //                 </div>
  //                 <p className="text-gray-600">{review.comment}</p>
  //               </div>
  //             ))}
  //           </div>
  //         ) : (
  //           <p className="text-gray-500">No reviews yet.</p>
  //         )}
  //       </div>
  //       <motion.button
  //         whileHover={{ scale: 1.05 }}
  //         whileTap={{ scale: 0.95 }}
  //         className={`px-6 py-3 rounded-lg flex items-center justify-center w-full ${
  //           isAgentSelected(app) ? 'bg-green-500 text-white' : 'bg-purple-500 hover:bg-purple-600 text-white'
  //         }`}
  //         onClick={() => onSelectAgent(app)}
  //         disabled={app.action === 'Coming Soon'}
  //       >
  //         {isAgentSelected(app) ? (
  //           <>
  //             <FiCheck className="mr-2" /> Added to Workspace
  //           </>
  //         ) : app.action === 'Coming Soon' ? (
  //           'Coming Soon'
  //         ) : (
  //           <>
  //             <FiPlus className="mr-2" /> Add to Workspace
  //           </>
  //         )}
  //       </motion.button>
  //     </motion.div>
  //   </motion.div>
  // );

  return (
    <div className="h-full p-5 pt-8  text-white mt-16 md:mt-0 ">
      <div className="max-w-7xl mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className=" relative bg-gradient-to-r from-teal-700  via-teal-600/60 to-teal-100/15 text-white rounded-2xl p-8 mb-8 shadow-lg"
        >
          <div className="flex items-center justify-between">
            <div className="max-w-lg">
              <h1 className="text-3xl md:text-4xl font-bold mb-2">AI Agents Marketplace</h1>
              <p className="text-sm md:text-base text-purple-100">
                Discover and integrate advanced AI agents to supercharge your workflow.
              </p>
            </div>
            <img src={market} alt="Marketplace" className="hidden md:block drop-shadow-xl h-40 opacity-80 bottom-0 object-contain absolute right-20 " />
          </div>
        </motion.div>

        <div className="flex md:flex-row flex-col justify-between space-y-5 md:space-y-0 mb-8">
          <div className="relative flex-grow mr-4">
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search apps..."
              className="w-full pl-10 pr-4 py-2 rounded-lg bg-white bg-opacity-10 focus:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-purple-500 text-black transition-all shadow-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex space-y-4 md:space-y-0 flex-wrap space-x-3">
            {categories.map((category) => (
              <button
                key={category}
                className={`px-4 py-2 rounded-3xl text-black ${
                  selectedCategory === category
                    ? 'bg-purple-500 text-white'
                    : 'bg-white bg-opacity-10 hover:bg-opacity-20  shadow-lg'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <h2 className="text-2xl font-bold mb-6 text-black">Featured Apps</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <AnimatePresence>
            {filteredApps.slice(0, 3).map((app) => renderAppCard(app))}
          </AnimatePresence>
        </div>

        {/* <h2 className="text-2xl font-bold mb-6">All Apps</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <AnimatePresence>
            {filteredApps.map((app) => renderAppCard(app))}
          </AnimatePresence>
        </div> */}

        {/* <AnimatePresence>
          {selectedApp && renderDetailedView(selectedApp)}
        </AnimatePresence> */}
      </div>
    </div>
  );
};

export default Marketplace;