// AdvancedSettings.tsx
import React, { useState } from 'react';
import Toggle from './Toggle';

import {useTheme} from '../../contexts/ThemeContext'

const AdvancedSettings: React.FC = () => {
  const [apiUrl, setApiUrl] = useState('');
  const { darkMode } = useTheme();
  const [notificationSetting, setNotificationSetting] = useState('email');

  const handleCustomizeAIModels = () => {
    // Implement AI model customization logic
    console.log('Customize AI Models clicked');
  };

  const handleExportData = () => {
    // Implement data export logic
    console.log('Export Data clicked');
  };

  const handleDeleteAccount = () => {
    // Implement account deletion logic
    console.log('Delete Account clicked');
  };

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">API URL</h2>
        <input
          type="text"
          value={apiUrl}
          onChange={(e) => setApiUrl(e.target.value)}
          placeholder="Enter API URL"
          className={`w-full p-2 rounded border ${
            darkMode ? 'bg-theme-dark-bg-to border-theme-border text-white' : 'bg-white text-gray-900'
          }`}
        />
      </div>

      

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Notification Settings</h2>
        <select
          value={notificationSetting}
          onChange={(e) => setNotificationSetting(e.target.value)}
          className={`w-1/ p-2 rounded border ${
            darkMode ? 'bg-theme-dark-bg-to border-theme-border text-white' : 'bg-white text-gray-900'
          }`}
        >
          <option value="email">Email Notification</option>
          <option value="push">Push Notification</option>
          <option value="sms">SMS Notification</option>
        </select>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">AI Model Preferences</h2>
        <div className="grid grid-cols-3 gap-4 mb-4 max-w-80 ">
          <div className={`p-4 rounded text-center ${darkMode ? 'bg-gray-950' : 'bg-gray-200'}`}>GPT-4</div>
          <div className={`p-4 rounded text-center  ${darkMode ? 'bg-gray-950' : 'bg-gray-200'}`}>BERT</div>
          <div className={`p-4 rounded text-center  ${darkMode ? 'bg-gray-950' : 'bg-gray-200'}`}>DALL-E</div>
        </div>
        <button
          className="bg-theme-button-bg text-white px-4 py-2 rounded"
          onClick={handleCustomizeAIModels}
        >
          Customize AI Models
        </button>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Blockchain Statistics</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {['Total Transactions', 'Active Smart Contracts', 'Total Value Locked', 'Average Block Time'].map((stat) => (
            <div
              key={stat}
              className={`p-4 rounded border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}
            >
              <h3 className="font-semibold">{stat}</h3>
              <p className="text-2xl font-bold mt-2">0</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Data Management</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="font-semibold mb-2">Data Export</h3>
            <p className="text-sm text-gray-500 mb-2">Download all your data in a portable format</p>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={handleExportData}
            >
              Export Data
            </button>
          </div>
          <div>
            <h3 className="font-semibold mb-2">Account Deletion</h3>
            <p className="text-sm text-gray-500 mb-2">Permanently delete your account</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettings;