import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FiSearch, FiCamera, FiUser, FiSettings, FiTrash2, FiCopy } from "react-icons/fi";
import ProfilePicture from "./ProfilePicture";
import PersonalInfoForm from "./PersonalInfoForm";
import ContactForm from "./ContactForm";
import ActivityFeed from "./ActivityFeed";
import { Toaster, toast } from "sonner";
import imageCompression from 'browser-image-compression';
import { updateProfilePic, getOrGenerateProfilePic } from '../../utils/profilePicManager';

interface User {
  name: string;
  email: string;
  phone: string;
  bio: string;
  profilePicture: string;
}

interface Contact {
  id: string;
  name: string;
  publicKey: string;
  relation: string;
}

const ProfilePage: React.FC = () => {
  const { colors, darkMode } = useTheme();
  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    phone: "",
    bio: "",
    profilePicture: "",
  });
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [activeTab, setActiveTab] = useState<'personalInfo' | 'addContact'>('personalInfo');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Load user data from local storage
    const storedUser = localStorage.getItem('userData');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // Load contacts from local storage
    const storedContacts = localStorage.getItem('contacts');
    if (storedContacts) {
      setContacts(JSON.parse(storedContacts));
    }
    // Load profile picture
    const profilePic = getOrGenerateProfilePic();
    setUser(prevUser => ({ ...prevUser, profilePicture: profilePic }));

    // Load wallet name
    const walletName = localStorage.getItem('walletname');
    if (walletName) {
      setUser(prevUser => ({ ...prevUser, name: walletName }));
    }
  }, []);

  const handleSaveUserInfo = (updatedInfo: Partial<User>) => {
    const newUserInfo = { ...user, ...updatedInfo };
    setUser(newUserInfo);
    localStorage.setItem('userData', JSON.stringify(newUserInfo));
    localStorage.setItem('walletname', newUserInfo.name); // Save wallet name
  };
  const handleProfilePicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          const success = updateProfilePic(base64String);
          if (success) {
            setUser(prevUser => ({ ...prevUser, profilePicture: base64String }));
            toast.success('Profile picture updated successfully');
          } else {
            toast.error('Failed to update profile picture. The image might be too large.');
          }
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
        toast.error('Error processing image. Please try again.');
      }
    }
  };
  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleAddContact = (newContact: Omit<Contact, 'id'>) => {
    const isDuplicate = contacts.some(
      contact => contact.name === newContact.name || contact.publicKey === newContact.publicKey
    );

    if (isDuplicate) {
      toast.error("A contact with this name or public key already exists.");
      return;
    }

    const contactWithId = { ...newContact, id: Date.now().toString() };
    const updatedContacts = [...contacts, contactWithId];
    setContacts(updatedContacts);
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
    toast.success("Contact added successfully!");
  };

  const handleDeleteContact = (id: string) => {
    const updatedContacts = contacts.filter(contact => contact.id !== id);
    setContacts(updatedContacts);
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
    toast.success("Contact deleted successfully!");
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.info("Public key copied to clipboard!");
  };

  return (
    <div
    className={`min-h-screen p-8 py-4 ${
      darkMode
        ? "bg-gradient-to-tl from-theme-dark-bg-from to-theme-dark-bg-to text-white"
        : "bg-gray-100 text-gray-900"
    }`}
    style={{ color: colors.text }}
  >
    <header className="flex justify-between items-center mb-12">
      <h1 className="text-2xl font-bold">Profile</h1>
      <div className="flex space-x-4">
        <FiSearch className="w-5 h-5" />
        <FiCamera className="w-5 h-5" />
        <FiUser className="w-5 h-5" />
        <FiSettings className="w-5 h-5" />
      </div>
    </header>

    <div className="absolute top-16 left-60 right-0 h-px bg-gradient-to-r from-transparent via-teal-500 to-transparent opacity-60"></div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div>
        <div className="flex items-center space-x-4 mb-8 mt-10 px-6 gap-9 border rounded-2xl border-theme-border">
          <div className="relative -top-10">
            <ProfilePicture src={user.profilePicture} />
            <button
              onClick={triggerFileInput}
              className="absolute bottom-0 right-0 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-600 transition-colors"
            >
              <FiCamera className="text-white" />
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleProfilePicChange}
            />
          </div>
          <div>
            <h2 className="text-xl font-semibold">User Name</h2>
            <p className="text-lg">{user.name}</p>
          </div>
        </div>
        <div className="mb-4 flex space-x-4">
          <button
            onClick={() => setActiveTab('personalInfo')}
            className={`px-4 py-2 rounded-t-lg ${
              activeTab === 'personalInfo' ? 'bg-theme-button-bg text-white' : 'bg-gray-400 text-gray-700'
            }`}
          >
            Personal Information
          </button>
          <button
            onClick={() => setActiveTab('addContact')}
            className={`px-4 py-2 rounded-t-lg ${
              activeTab === 'addContact' ? 'bg-theme-button-bg text-white' : 'bg-gray-400 text-gray-700'
            }`}
          >
            Add Contact
          </button>
        </div>
        {activeTab === 'personalInfo' ? (
          <PersonalInfoForm user={user} onSave={handleSaveUserInfo} />
        ) : (
          <ContactForm onAddContact={handleAddContact} />
        )}
      </div>
        <div>
          <h2 className="text-3xl font-bold mb-4">Profile Details</h2>
          <div className="border-b-2 mb-4" style={{ borderColor: colors.accent }}>
            <span className="text-xl font-semibold text-theme-text-selected">
              Contact
            </span>
          </div>
          <div className="mb-8">
            <h3 className="text-xl font-semibold mb-4">Contacts</h3>
            {contacts.length === 0 ? (
              <p className="text-center text-gray-500">No contacts added. Please add a contact.</p>
            ) : (
              contacts.map(contact => (
                <div key={contact.id} className="flex justify-between items-center mb-2 p-2 border rounded hover:bg-opacity-10 hover:bg-white transition-colors">
                  <div>
                    <p className="font-semibold">{contact.name}</p>
                    <p className="text-sm font-mono break-all">{contact.publicKey}</p>
                  </div>
                  <div className="flex space-x-2">
                    <button onClick={() => copyToClipboard(contact.publicKey)} className="text-blue-500 hover:text-blue-600">
                      <FiCopy />
                    </button>
                    <button onClick={() => handleDeleteContact(contact.id)} className="text-red-500 hover:text-red-600">
                      <FiTrash2 />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          <ActivityFeed />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;