interface ChainConfigSol {
    rpcEndpoint: string;
    prefix: string;
    denom: string;
    feeAmount: string;
    gas: string;
  }
  
  const chainConfigSol: ChainConfigSol = {
    // rpcEndpoint: 'http://95.217.179.152:8086/solana-rpc',
    // rpcEndpoint: 'https://api.mainnet-beta.solana.com',
    rpcEndpoint: 'https://solana-backend.zenscape.one/proxy/solana-rpc',


    prefix: 'solana',
    denom: 'sol', 
    feeAmount: '10000', 
    gas: '300000'
  };
  
export default chainConfigSol;