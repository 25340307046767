// src/components/ActivityFeed.tsx

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const ActivityFeed: React.FC = () => {
  const { colors } = useTheme();

  const activities = [
    { id: 1, type: 'transaction', description: 'Sent 0.5 ETH to 0x1234...5678' },
    { id: 2, type: 'ai_interaction', description: 'Chat with AI Agent: Financial Advisor' },
    { id: 3, type: 'wallet_update', description: 'Added new Solana wallet' },
    { id: 4, type: 'security', description: 'Enabled 2FA authentication' },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4" style={{ color: colors.accent }}>Recent Activity</h3>
      <ul className="space-y-4">
        {activities.map((activity) => (
          <li key={activity.id} className="p-3 rounded" style={{ backgroundColor: colors.inputBg }}>
            <p className="text-sm">{activity.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityFeed;