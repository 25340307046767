import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Assume this is imported from your Solana transaction handler
import { transactionStatusSubject } from './SolanaTransactionHandler';

interface TransactionStatusProps {
  initialStatus: DetailedStatus;
}

// Define a more detailed transaction status type
type DetailedStatus = {
  stage: 'initializing' | 'processing' | 'confirming' | 'completed' | 'error';
  message: string;
  progress: number;
};

const TransactionStatus: React.FC<TransactionStatusProps> = ({ initialStatus }) => {
  const [status, setStatus] = useState<DetailedStatus>(initialStatus);

  useEffect(() => {
    const destroy$ = new Subject<void>();

    transactionStatusSubject
      .pipe(takeUntil(destroy$))
      .subscribe((newStatus: DetailedStatus) => {
        setStatus(newStatus);
      });

    return () => {
      destroy$.next();
      destroy$.complete();
    };
  }, []);

  const stageColors = {
    initializing: ' from-yellow-400 to-yellow-600',
    processing: 'from-blue-400 to-blue-600',
    confirming: 'from-green-400 to-green-600',
    completed: 'from-purple-400 to-purple-600',
    error: 'from-red-400 to-red-600',
  };

  const iconVariants = {
    initializing: { rotate: 0 },
    processing: { rotate: 360, transition: { duration: 2, repeat: Infinity, ease: 'linear' } },
    confirming: { scale: [1, 1.2, 1], transition: { duration: 1, repeat: Infinity } },
    completed: { scale: 1.2, transition: { duration: 0.3 } },
    error: { x: [-5, 5, -5, 5, 0], transition: { duration: 0.5 } },
  };

  return (
    <AnimatePresence>
      <motion.div
        key={status.stage}
        // initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        // exit={{ opacity: 0, y: -20 }}
        // transition={{ duration: 0.3 }}
        className={`relative bg-gradient-to-br ${stageColors[status.stage]} p-6 rounded-xl shadow-xl overflow-hidden max-w-sm mr-auto`}
      >
        <motion.div 
          className="absolute top-0 left-0 w-full h-full"
        //   initial={{ opacity: 0 }}
          animate={{ opacity: [0.3, 0.5, 0.3] }}
          transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
        >
          <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-white rounded-full filter blur-3xl opacity-30"></div>
          <div className="absolute bottom-1/4 right-1/4 w-32 h-32 bg-white rounded-full filter blur-3xl opacity-30"></div>
        </motion.div>

        <div className="relative z-10 flex flex-col items-center">
          <motion.div
            variants={iconVariants}
            animate={status.stage}
            className="mb-4"
          >
            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="6" height="6" rx="1" fill="#FFFFFF" />
              <rect x="14" y="4" width="6" height="6" rx="1" fill="#FFFFFF" />
              <rect x="4" y="14" width="6" height="6" rx="1" fill="#FFFFFF" />
              <rect x="14" y="14" width="6" height="6" rx="1" fill="#FFFFFF" />
            </svg>
          </motion.div>

          <h3 className="text-xl font-bold text-white text-center mb-2">
            {status.stage.charAt(0).toUpperCase() + status.stage.slice(1)}
          </h3>

          <motion.div
            // initial={{ opacity: 0, y: 10 }}
            // animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white bg-opacity-20 rounded-lg p-4 w-full"
          >
            <p className="text-white text-center">{status.message}</p>
          </motion.div>

          {status.stage !== 'completed' && status.stage !== 'error' && (
            <motion.div 
              className="w-full bg-white bg-opacity-30 h-2 mt-4 rounded-full overflow-hidden"
            //   initial={{ width: 0 }}
              animate={{ width: '100%' }}
            //   transition={{ duration: 0.5 }}
            >
              <motion.div 
                className="h-full bg-white"
                // initial={{ width: 0 }}
                animate={{ width: `${status.progress}%` }}
                // transition={{ duration: 0.5 }}
              />
            </motion.div>
          )}
          <p className="text-white mt-2">{status.progress}%</p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TransactionStatus;