// File: src/utils/walletUtils.ts

import { ethers } from 'ethers';
import {  Account } from './types';
import solanabg from "../../Assets//Image/solana.svg";
import { Connection, PublicKey, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as bip39 from 'bip39';
import { derivePath } from 'ed25519-hd-key';




const URL ="https://solana-backend.zenscape.one/proxy/solana-rpc"
// const URL ="http://95.217.179.152:8086/solana-rpc"
export async function getWalletInfoFromMnemonic(mnemonic: string, name: string): Promise<Account[]> {
  try {
    // Validate mnemonic
    if (!ethers.Mnemonic.isValidMnemonic(mnemonic)) {
      throw new Error('Invalid mnemonic');
    }
    const wallets: Account[] = [];

    // Create Solana wallet
    const seed = await bip39.mnemonicToSeed(mnemonic);
    const derivationPath = "m/44'/501'/0'/0'";
    const derivedSeed = derivePath(derivationPath, seed.toString('hex')).key;
    const solanaKeypair = Keypair.fromSeed(Uint8Array.from(derivedSeed));
    const solanaPublicKey = new PublicKey(solanaKeypair.publicKey);
    let formattedSolanaBalance = '0';
    try {
      const solanaConnection = new Connection(URL);
      console.log('Solana Public Key:', solanaPublicKey.toBase58());
      const solanaBalance = await solanaConnection.getBalance(solanaPublicKey);
      console.log('Raw Solana Balance:', solanaBalance);
      formattedSolanaBalance = (solanaBalance / LAMPORTS_PER_SOL).toFixed(9);
    } catch (error) {
      console.error('Error fetching Solana balance:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      // Set a default balance of 0 if there's an error
      // formattedSolanaBalance = '0';
    }

    wallets.push({
      name: `${name} - Solana`,
      mnemonic,
      address: solanaPublicKey.toBase58(),
      balance: formattedSolanaBalance,
      network: 'solana',
      icon: solanabg,
      // Add Solana icon here if available
    });

    return wallets;
  } catch (error) {
    console.error('Error fetching wallet info:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to get wallet info: ${error.message}`);
    } else {
      throw new Error('An unknown error occurred while fetching wallet info');
    }
  }
}

export function validateWalletName(name: string): boolean {
  return name.length > 0 && name.length <= 50;
}

export function shortenAddress(address: string | undefined | null, chars = 4): string {
  if (!address) return 'N/A';
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
}

export async function getWalletBalance(address: string, network: string): Promise<string> {
  try {
     if (network === 'solana') {
      try {
        const connection = new Connection(URL);
        const publicKey = new PublicKey(address);
        console.log('Fetching balance for Solana address:', address);
        const balance = await connection.getBalance(publicKey);
        console.log('Raw Solana Balance:', balance);
        return (balance / LAMPORTS_PER_SOL).toFixed(9);
      } catch (error) {
        console.error('Error fetching Solana balance:', error);
        if (error instanceof Error) {
          console.error('Error message:', error.message);
          console.error('Error stack:', error.stack);
        }
        return '0';
      }
    } else {
      throw new Error('Unsupported network');
    }
  } catch (error) {
    console.error('Error fetching wallet balance:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    return '0';
  }
}

export async function getSolanaWalletBalance(address: string): Promise<string> {
  try {
    const connection = new Connection(URL);
    const publicKey = new PublicKey(address);
    const balance = await connection.getBalance(publicKey);
    return (balance / LAMPORTS_PER_SOL).toFixed(9); // Convert lamports to SOL
  } catch (error) {
    console.error('Error fetching Solana wallet balance:', error);
    throw new Error('Failed to fetch Solana wallet balance');
  }
}

// // Add this function to getWalletInfoFromMnemonic
// if (name.toLowerCase().includes('solana')) {
//   // Implement Solana-specific wallet creation logic here
//   // This is a placeholder and needs to be implemented properly
//   const solanaAddress = 'SOLANA_ADDRESS_PLACEHOLDER';
//   const solanaBalance = await getSolanaWalletBalance(solanaAddress);
  
//   return {
//     name,
//     mnemonic,
//     address: solanaAddress,
//     balance: solanaBalance,
//   };
// }