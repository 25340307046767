import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import {
  FiRepeat,
  FiChevronUp,
  FiCopy,
  FiExternalLink,
  FiGlobe,
  FiCheckCircle,
  FiAlertTriangle,
  FiChevronDown,
  FiInfo,
  FiClock,
  FiCpu,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../contexts/ThemeContext";

interface MessageProps {
  message: Message;
  messages: Message[];
  copyToClipboard: (text: string) => void;
  setExpandedImage: (url: string | null) => void;
  regenerateResponse: () => void;
  staticBotImageUrl: string;
  loadingGifUrl: string;
  completionGifUrl: string;
  profile: string;
  agent: string;
  onNextStepClick: (step: string) => void;
}

interface Message {
  type: string;
  text: string;
  time: string;
  transactionHash?: string;
  code?: string;
  language?: string;
  imageUrl?: string;
  attachments?: File[];
  error?: string;
  intent?: string;
  sessionId?: string;
  confidenceScore?: number;
  requestCounter?: number;
  fulfillmentCounter?: number;
  nextSteps?: string[];
  intents_info?: {
    intents: Array<{
      intent: string;
      confidence: number;
    }>;
    request_counter: number;
    fulfillment_counter: number;
  };
}

const MessageComponent: React.FC<MessageProps> = ({
  message,
  messages,
  copyToClipboard,
  setExpandedImage,
  regenerateResponse,
  staticBotImageUrl,
  loadingGifUrl,
  completionGifUrl,
  profile,
  agent,
  onNextStepClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState("message");
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  const [transactionType, setTransactionType] = useState("unknown");
  const [network, setNetwork] = useState("unknown");
  // Inside the component, add this line near the top
  const { darkMode } = useTheme();
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (message.type === "transaction" || message.type === "error") {
      const userMessage =
        messages.find((msg) => msg.type === "user")?.text.toLowerCase() || "";

      // Detect transaction type
      if (userMessage.includes("send")) setTransactionType("send");
      else if (userMessage.includes("delegate")) setTransactionType("delegate");
      else if (userMessage.includes("stake")) setTransactionType("stake");
      else if (userMessage.includes("redelegate"))
        setTransactionType("redelegate");
      else if (userMessage.includes("undelegate"))
        setTransactionType("undelegate");
      else if (userMessage.includes("swap")) setTransactionType("swap");
    }
  }, [message, messages]);

  //
  const shortenAddress = (address: string, chars = 4) => {
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  const gasFee = "1.6";

  const detectLanguage = (code: string, specifiedLanguage?: string): string => {
    if (specifiedLanguage) {
      return specifiedLanguage.toLowerCase();
    }

    const languagePatterns = {
      cpp: /#include|using namespace|int main\s*\(/,
      python: /import\s+|def\s+\w+\s*\(|print\s*\(/,
      javascript:
        /function\s+\w+\s*\(|const\s+\w+\s*=|let\s+\w+\s*=|var\s+\w+\s*=/,
      java: /public\s+class|System\.out\.println/,
      bash: /#!/,
      golang: /package\s+main|func\s+main\s*\(\)/,
      console: /^\$\s|^>\s/,
      shell: /^\$\s|^>\s/,
    };

    for (const [lang, pattern] of Object.entries(languagePatterns)) {
      if (pattern.test(code)) {
        return lang;
      }
    }

    return "text";
  };

  const renderTransactionCard = () => {
    const userMessage = messages.find((msg) => msg.type === "user")?.text || "";
    const transactionRegex = /(\w+)\s+(\d+(\.\d+)?)\s+(\w+)\s+to\s+(\S+)/i;
    const match = userMessage.match(transactionRegex);

    const transactionType = match ? match[1].toLowerCase() : "unknown";
    const amount = match ? match[2] : "Unknown";
    const currency = match ? match[4] : `${agent}||SOL`;
    const recipient = match ? match[5] : "Unknown";
    const transactionHash =
      message.transactionHash ||
      (isTransactionHash(message.text) ? message.text : "Unknown");
    const gasFee = "0.005";

    const isSolanaTransaction = agent === "Solana";

    if (message.type === "error") {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-[#0a3d3d] text-[#ff4c4c] p-6 rounded-xl shadow-lg max-w-md mr-auto border border-theme-border"
        >
          <div className="flex items-center mb-4">
            <FiAlertTriangle className="w-6 h-6 mr-2" />
            <h2 className="text-2xl text-[#ff4c4c] font-bold"></h2>
          </div>
          <div className="bg-[#0c4644] text-[#f5f5f5] rounded-lg p-4 mb-4">
            <p className="text-sm">{message.error}</p>
          </div>
          <p className="text-sm mb-4 text-[#9f9f9f]">Please try again.</p>
          <button
            onClick={() => copyToClipboard(message.error || "")}
            className="flex items-center justify-center w-full px-4 py-2 text-[#f5f5f5] bg-[#0b5a4d] rounded-lg text-sm hover:bg-[#1d5d59] transition-colors duration-200"
          >
            <FiCopy className="mr-2" /> Copy Error
          </button>
        </motion.div>
      );
    }

    // const ProgressBar = ({ value, max }) => (
    //   <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4 dark:bg-gray-700">
    //     <div className="bg-teal-500 h-2.5 rounded-full" style={{ width: `${(value / max) * 100}%` }}></div>
    //   </div>
    // );

    const cardProps = isSolanaTransaction
      ? {
          bgColorClass: "from-green-500 to-green-700",
          explorerUrl: `https://solscan.io/tx/${transactionHash}`,
          copyText: "Copy Signature",
        }
      : {
          bgColorClass: "from-green-500 to-green-700",
          explorerUrl: `https://www.mintscan.io/${network}/txs/${transactionHash}`,
          copyText: "Copy Hash",
        };

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-[#0d2d2d] text-[#4cffdf] p-6 rounded-xl shadow-lg max-w-md mr-auto border border-theme-border"
      >
        <div className="flex items-center mb-4">
          <svg
            className="w-6 h-6 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6L9 17L4 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h2 className="text-2xl font-bold">Transaction Successful</h2>
        </div>
        <div className="bg-[#164948] rounded-lg p-4 mb-4">
          <p className="text-sm break-all">{transactionHash}</p>
        </div>
        <div className="flex justify-between">
          <button
            // onClick={onCopyHash}
            className="flex items-center justify-center px-4 py-2 bg-[#164948] rounded-lg text-sm hover:bg-[#1d5e5d] transition-colors duration-200"
          >
            <FiCopy className="mr-2" />
            Copy Hash
          </button>
          <button
            // onClick={onViewExplorer}
            className="flex items-center justify-center px-4 py-2 bg-[#164948] rounded-lg text-sm hover:bg-[#1d5e5d] transition-colors duration-200"
          >
            <FiExternalLink className="mr-2" />
            View on Explorer
          </button>
        </div>
        <div className="mt-4 flex justify-between text-sm text-[#4cffdf]/70">
          <span>Request counter: 1</span>
          <span>Fulfilment Counter: 1</span>
        </div>
      </motion.div>
    );
    // return null;
  };

  const isTransactionHash = (text: string) => {
    // Check if the text matches the pattern of a transaction hash
    return /^[A-Za-z0-9]{64,}$/.test(text.trim());
  };

  const toggleSection = (id: string) => {
    setExpandedSections((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderMessageContent = () => {
    if (message.type === "user") {
      return (
        <div
          className={`inline-block p-1 rounded-2xl text-pretty max-w-full space-y-3 pb-4 border md:pb-2 px-7 ${
            darkMode
              ? "bg-teal-400/20 border-teal-600 text-white"
              : "bg-white border-gray-200 text-black"
          } font-normal md:font-normal`}
        >
          {message.attachments && message.attachments.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-3">
              {message.attachments.map((file, index) => (
                <div
                  key={index}
                  className={`px-3 py-1 rounded-full text-xs font-medium ${
                    darkMode
                      ? "bg-blue-900 text-blue-200"
                      : "bg-blue-100 text-blue-800"
                  }`}
                >
                  {file.name}
                </div>
              ))}
            </div>
          )}
          <ReactMarkdown
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            remarkPlugins={[remarkGfm, remarkMath]}
            components={{
              code: ({ node, inline, className, children, ...props }: any) => {
                const match = /language-(\w+)/.exec(className || "");
                const lang = !inline && match ? match[1] : "";
                const code = String(children).replace(/\n$/, "");

                if (inline) {
                  return (
                    <span className="relative group inline-flex items-center">
                      <code
                        className="px-1.5 py-0.5 rounded font-mono text-sm bg-gray-800 text-gray-200 border border-gray-700 shadow-sm"
                        {...props}
                      >
                        {children}
                      </code>
                      <button
                        onClick={() => copyToClipboard(code)}
                        className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-400 hover:text-gray-200"
                        aria-label="Copy code"
                      >
                        <FiCopy size={14} />
                      </button>
                    </span>
                  );
                }

                return (
                  <div className="relative mt-4 mb-4 bg-gray-800 rounded-lg overflow-hidden">
                    <div className="flex justify-between items-center px-4 py-2 bg-gray-700 text-white">
                      <span className="text-sm font-mono">
                        {lang || "plaintext"}
                      </span>
                      <button
                        onClick={() => copyToClipboard(code)}
                        className="p-1 hover:bg-gray-600 rounded transition-colors duration-200"
                      >
                        <FiCopy size={16} />
                      </button>
                    </div>
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language={lang}
                      PreTag="div"
                      className="!m-0 !rounded-t-none"
                      showLineNumbers
                      {...props}
                    >
                      {code}
                    </SyntaxHighlighter>
                  </div>
                );
              },
              h1: ({ children }) => (
                <h1 className="text-3xl font-bold mt-6 mb-4 pb-2 border-b border-gray-300 dark:border-gray-700">
                  {children}
                </h1>
              ),
              h2: ({ children }) => (
                <h2 className="text-2xl font-semibold mt-5 mb-3">{children}</h2>
              ),
              h3: ({ children }) => (
                <h3 className="text-xl font-medium mt-4 mb-2">{children}</h3>
              ),
              p: ({ children }) => <p className="my-2 leading-7">{children}</p>,
              ul: ({ children }) => (
                <ul className="list-disc pl-5 space-y-2 my-3">{children}</ul>
              ),
              ol: ({ children }) => (
                <ol className="list-decimal pl-5 space-y-2 my-3">{children}</ol>
              ),
              li: ({ children }) => <li className="mb-1">{children}</li>,
              blockquote: ({ children }) => (
                <blockquote
                  className={`border-l-4 pl-4 py-2 my-4 italic ${
                    darkMode
                      ? "border-gray-600 text-gray-400"
                      : "border-gray-300 text-gray-600"
                  }`}
                >
                  {children}
                </blockquote>
              ),
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  className="text-blue-500 hover:text-blue-600 underline transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.children}
                </a>
              ),
              img: ({ src, alt, ...props }) => (
                <img
                  src={src}
                  alt={alt}
                  {...props}
                  className="max-w-full h-auto rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 my-4"
                  onClick={() => src && setExpandedImage(src)}
                />
              ),
              table: ({ children }) => (
                <div className="overflow-x-auto my-4">
                  <table className="min-w-full divide-y divide-gray-700 bg-gray-800 text-gray-300">
                    {children}
                  </table>
                </div>
              ),
              th: ({ children }) => (
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider bg-gray-700">
                  {children}
                </th>
              ),
              td: ({ children }) => (
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {children}
                </td>
              ),
              hr: () => (
                <hr
                  className={`my-6 border-t ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                />
              ),
              details: ({ children }) => {
                if (!children) return null;

                const childrenArray = React.Children.toArray(children);
                const summary = childrenArray[0];
                const content = childrenArray.slice(1);
                const id = Math.random().toString(36).substring(7);

                return (
                  <div
                    className={`border rounded-lg my-4 ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    }`}
                  >
                    <button
                      className={`flex justify-between items-center w-full px-4 py-2 text-left font-medium ${
                        darkMode
                          ? "bg-gray-800 hover:bg-gray-700"
                          : "bg-gray-100 hover:bg-gray-200"
                      }`}
                      onClick={() => toggleSection(id)}
                    >
                      {summary}
                      {expandedSections[id] ? (
                        <FiChevronUp />
                      ) : (
                        <FiChevronDown />
                      )}
                    </button>
                    <AnimatePresence>
                      {expandedSections[id] && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className={`px-4 py-2 ${
                            darkMode ? "bg-gray-900" : "bg-white"
                          }`}
                        >
                          {content}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                );
              },
              kbd: ({ children }) => (
                <kbd
                  className={`px-2 py-1.5 text-xs font-semibold rounded-lg ${
                    darkMode
                      ? "bg-gray-700 text-gray-300"
                      : "bg-gray-100 text-gray-800"
                  }`}
                >
                  {children}
                </kbd>
              ),
            }}
          >
            {message.text}
          </ReactMarkdown>
        </div>
      );
    }

    if (
      message.type === "transaction" ||
      message.type === "error" ||
      isTransactionHash(message.text)
    ) {
      return renderTransactionCard();
    }

    const token=message.text;
    const token_length= token.length;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-gradient-to-br from-[#0d2d2d] to-[#1a3c3c] text-white shadow-lg max-w-xl mr-auto border border-teal-800 rounded-xl overflow-hidden"
      >
        {/* Header */}
        <div className="bg-gradient-to-br from-[#164948] to-[#063232] p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold">AI Response</h3>
            <div className="flex space-x-2">
              <button
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                  activeTab === "message"
                    ? "bg-teal-500 text-white shadow-lg"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
                onClick={() => setActiveTab("message")}
              >
                Message
              </button>
              <button
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                  activeTab === "details"
                    ? "bg-teal-500 text-white shadow-lg"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
                onClick={() => setActiveTab("details")}
              >
                Details
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 text-sm">
            <div className="bg-[#0d2d2d] p-3 rounded-lg">
              <p className="text-gray-400 flex items-center">
                <FiInfo className="mr-2" /> Intent
              </p>
              <p className="font-medium truncate">{message.intent}</p>
            </div>
            <div className="bg-[#0d2d2d] p-3 rounded-lg">
              <p className="text-gray-400 flex items-center">
                <FiCpu className="mr-2" /> Session ID
              </p>
              <p className="font-medium truncate">{message.sessionId}</p>
            </div>
            <div className="bg-[#0d2d2d] p-3 rounded-lg">
              <p className="text-gray-400 flex items-center">
                <FiGlobe className="mr-2" /> Confidence Score
              </p>
              <p className="font-medium">{message.confidenceScore}</p>
            </div>
          </div>
        </div>

        {/* Content */}
        <AnimatePresence mode="wait">
          {activeTab === "message" && (
            <motion.div
              key="message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-gradient-to-br from-[#0d2424] to-[#063232] p-6"
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                remarkPlugins={[remarkGfm, remarkMath]}
                components={{
                  code: ({
                    node,
                    inline,
                    className,
                    children,
                    ...props
                  }: any) => {
                    const match = /language-(\w+)/.exec(className || "");
                    const lang = !inline && match ? match[1] : "";
                    const code = String(children).replace(/\n$/, "");

                    if (inline) {
                      return (
                        <span className="relative group inline-flex items-center">
                          <code
                            className="px-1.5 py-0.5 rounded font-mono text-sm bg-gray-800 text-gray-200 border border-gray-700 shadow-sm"
                            {...props}
                          >
                            {children}
                          </code>
                          <button
                            onClick={() => copyToClipboard(code)}
                            className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-400 hover:text-gray-200"
                            aria-label="Copy code"
                          >
                            <FiCopy size={14} />
                          </button>
                        </span>
                      );
                    }

                    return (
                      <div className="relative mt-4 mb-4 bg-gray-800 rounded-lg overflow-hidden">
                        <div className="flex justify-between items-center px-4 py-2 bg-gray-700 text-white">
                          <span className="text-sm font-mono">
                            {lang || "plaintext"}
                          </span>
                          <button
                            onClick={() => copyToClipboard(code)}
                            className="p-1 hover:bg-gray-600 rounded transition-colors duration-200"
                          >
                            <FiCopy size={16} />
                          </button>
                        </div>
                        <SyntaxHighlighter
                          style={vscDarkPlus}
                          language={lang}
                          PreTag="div"
                          className="!m-0 !rounded-t-none"
                          showLineNumbers
                          {...props}
                        >
                          {code}
                        </SyntaxHighlighter>
                      </div>
                    );
                  },
                  h1: ({ children }) => (
                    <h1 className="text-3xl font-bold mt-6 mb-4 pb-2 border-b border-gray-300 dark:border-gray-700">
                      {children}
                    </h1>
                  ),
                  h2: ({ children }) => (
                    <h2 className="text-2xl font-semibold mt-5 mb-3">
                      {children}
                    </h2>
                  ),
                  h3: ({ children }) => (
                    <h3 className="text-xl font-medium mt-4 mb-2">
                      {children}
                    </h3>
                  ),
                  p: ({ children }) => (
                    <p className="my-2 leading-7">{children}</p>
                  ),
                  ul: ({ children }) => (
                    <ul className="list-disc pl-5 space-y-2 my-3">
                      {children}
                    </ul>
                  ),
                  ol: ({ children }) => (
                    <ol className="list-decimal pl-5 space-y-2 my-3">
                      {children}
                    </ol>
                  ),
                  li: ({ children }) => <li className="mb-1">{children}</li>,
                  blockquote: ({ children }) => (
                    <blockquote
                      className={`border-l-4 pl-4 py-2 my-4 italic ${
                        darkMode
                          ? "border-gray-600 text-gray-400"
                          : "border-gray-300 text-gray-600"
                      }`}
                    >
                      {children}
                    </blockquote>
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      className="text-blue-500 hover:text-blue-600 underline transition-colors duration-200"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  ),
                  img: ({ src, alt, ...props }) => (
                    <img
                      src={src}
                      alt={alt}
                      {...props}
                      className="max-w-full h-auto rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 my-4"
                      onClick={() => src && setExpandedImage(src)}
                    />
                  ),
                  table: ({ children }) => (
                    <div className="overflow-x-auto my-4">
                      <table className="min-w-full divide-y divide-gray-700 bg-gray-800 text-gray-300">
                        {children}
                      </table>
                    </div>
                  ),
                  th: ({ children }) => (
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider bg-gray-700">
                      {children}
                    </th>
                  ),
                  td: ({ children }) => (
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {children}
                    </td>
                  ),
                  hr: () => (
                    <hr
                      className={`my-6 border-t ${
                        darkMode ? "border-gray-700" : "border-gray-300"
                      }`}
                    />
                  ),
                  details: ({ children }) => {
                    if (!children) return null;

                    const childrenArray = React.Children.toArray(children);
                    const summary = childrenArray[0];
                    const content = childrenArray.slice(1);
                    const id = Math.random().toString(36).substring(7);

                    return (
                      <div
                        className={`border rounded-lg my-4 ${
                          darkMode ? "border-gray-700" : "border-gray-300"
                        }`}
                      >
                        <button
                          className={`flex justify-between items-center w-full px-4 py-2 text-left font-medium ${
                            darkMode
                              ? "bg-gray-800 hover:bg-gray-700"
                              : "bg-gray-100 hover:bg-gray-200"
                          }`}
                          onClick={() => toggleSection(id)}
                        >
                          {summary}
                          {expandedSections[id] ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )}
                        </button>
                        <AnimatePresence>
                          {expandedSections[id] && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                              className={`px-4 py-2 ${
                                darkMode ? "bg-gray-900" : "bg-white"
                              }`}
                            >
                              {content}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    );
                  },
                  kbd: ({ children }) => (
                    <kbd
                      className={`px-2 py-1.5 text-xs font-semibold rounded-lg ${
                        darkMode
                          ? "bg-gray-700 text-gray-300"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {children}
                    </kbd>
                  ),
                }}
              >
                {message.text}
              </ReactMarkdown>
            </motion.div>
          )}
          {activeTab === "details" && (
            <motion.div
              key="details"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-gradient-to-br from-[#0d2424] to-[#063232] p-6"
            >
              <h4 className="text-lg font-semibold mb-2">Additional Details</h4>
              <p className="text-sm text-gray-300 mb-4">
                This section provides more information about the AI's response
                and processing.
              </p>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className="bg-[#0d2d2d] p-3 rounded-lg">
                  <p className="text-gray-400 flex items-center">
                    <FiCpu className="mr-2" /> Model Version
                  </p>
                  <p className="font-medium">JusTx-70 MI</p>
                </div>
                <div className="bg-[#0d2d2d] p-3 rounded-lg">
                  <p className="text-gray-400 flex items-center">
                    <FiClock className="mr-2" /> Response Time
                  </p>
                  <p className="font-medium">18.9 seconds</p>
                </div>
                <div className="bg-[#0d2d2d] p-3 rounded-lg">
                  <p className="text-gray-400 flex items-center">
                    <FiInfo className="mr-2" /> Tokens Used
                  </p>
                  <p className="font-medium">{token_length}</p>
                </div>
                <div className="bg-[#0d2d2d] p-3 rounded-lg">
                  <p className="text-gray-400 flex items-center">
                    <FiGlobe className="mr-2" /> Language
                  </p>
                  <p className="font-medium">English</p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Footer */}
        <div className="bg-gradient-to-br from-[#164948] to-[#063232] p-4 flex justify-between items-center text-sm">
          <div>
            <span className="text-gray-400 mr-2">Request counter:</span>
            <span className="font-medium">{message.requestCounter}</span>
          </div>
          <div>
            <span className="text-gray-400 mr-2">Fulfilment Counter:</span>
            <span className="font-medium">{message.fulfillmentCounter}</span>
          </div>
        </div>

        {/* Expandable section */}
        <div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full bg-[#1e4d4d] hover:bg-[#265f5f] text-white py-3 px-4 flex items-center justify-between transition-colors duration-300"
          >
            <span className="font-medium">Next Steps</span>
            {isExpanded ? (
              <FiChevronUp size={20} />
            ) : (
              <FiChevronDown size={20} />
            )}
          </button>
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="bg-[#0d2d2d] overflow-hidden"
              >
                <div className="p-4 space-y-2">
                  {message.nextSteps?.map((step, index) => (
                    <motion.div
                      key={index}
                      className="bg-[#1e4d4d] text-white py-2 px-4 rounded-md flex items-center justify-between cursor-pointer"
                      whileHover={{ scale: 1.02 }}
                      onHoverStart={() => setHoveredStep(() => index)}
                      onHoverEnd={() => setHoveredStep(null)}
                      onClick={() => onNextStepClick(step)}
                    >
                      <span>{step}</span>
                      <div className="flex space-x-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(step);
                          }}
                          className="p-1 hover:bg-[#265f5f] rounded-full transition-colors duration-200"
                        >
                          <FiCopy size={16} />
                        </button>
                      </div>
                      {hoveredStep === index && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          className="absolute right-full mr-2 bg-gray-800 text-white p-2 rounded shadow-lg"
                        >
                          Click to execute this step
                        </motion.div>
                      )}
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Action buttons */}
      </motion.div>
    );
  };

  return (
    <div
      className={`mb-4 ${message.type === "user" ? "text-right" : "text-left"}`}
    >
      {renderMessageContent()}
      <div className="text-xs text-gray-400 mt-1">
        {message.type === "user" ? (
          <div className="-mt-3 flex items-end pr-4">
            <div
              className={`pb-0.5 caption1  ml-auto font-medium ${
                darkMode ? "text-gray-400" : "text-gray-500"
              } `}
            >
              {message.time}
            </div>
            <div className="relative ml-3 w-8 h-8 rounded-lg overflow-hidden border-2 border-teal-100">
              <img
                src={profile}
                alt="user"
                className="inline-block align-top transition-opacity object-cover bg-gray-200"
                loading="lazy"
              />
            </div>
          </div>
        ) : (
          <div className="-mt-3 flex items-end pl-4">
            <div className="relative shrink-0 w-[34px] h-[34px] mr-3 rounded-lg overflow-hidden border-2 border-teal-100">
              <img
                src={
                  message.type === "loading"
                    ? loadingGifUrl
                    : message.type === "transaction"
                    ? completionGifUrl
                    : staticBotImageUrl
                }
                alt="Bot"
                className="inline-block align-top transition-opacity bg-white opacity-100 object-contain"
                loading="lazy"
              />
            </div>
            <div className="-mt-9 flex items-end">
              <div className="caption1 text-n-4/50"></div>
              <button
                className="h-5 px-1 bg-n-3 font-medium rounded-md caption1 txt-n-6 transition-colors text-teal-950 hover:text-sky-500 dark:bg-n-7 bg-gray-200"
                onClick={() => copyToClipboard(message.text)}
              >
                <FiCopy size={12} />
              </button>
              {/* <button
                className="h-5 ml-3 px-1 bg-n-3 rounded-md caption1 txt-n-6 font-medium transition-colors text-teal-950 hover:text-sky-500 dark:bg-n-7 bg-gray-200"
                onClick={regenerateResponse}
              >
                <FiRepeat size={12} />
              </button> */}
              <div
                className={`pb-0.5 caption1  ml-3 font-medium ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } `}
              >
                {message.time}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageComponent;
