import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "./contexts/ThemeContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "./contexts/ThemeContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import "./App.css";

import ProfilePage from "./Components/Profile/ProfilePage";
import { toast } from "sonner";

import NewSidebar from "./Components/NewSidebar/NewSidebar";
import Onboarding1 from "./Components/Onboarding1/Onboarding1";
import Web3AuthLoginPage from "./Components/Web3Auth/Web3AuthLogin";
import MarketPlace from "./Components/MarketPlace/MarketPlace";
import ChatPage from "./Components/NewChatWindows/ChatPage";

import WalletPage from "./Components/WalletPage/WalletPage";
import Dashboard from "./Components/Main/Dashboard";
import SettingsPage from "./Components/SettingPage/SettingPage";
import solanaIcon from "./Assets/Image/solana.svg";

import {
  web3Auth,
  connectToWeb3Auth,
  getWeb3AuthPrivateKey,
} from "./services/web3auth";
import { LOGIN_PROVIDER, TypeOfLogin } from "@web3auth/auth-adapter";
import { telegramBotId } from "./config";
import { TelegramWidgetLoginUser } from "./gloabl";
import { GetTokenForWeb3AuthTelegramLogin } from "./services/justxutility";
import { justXUser } from "./services/justxuser";

interface Agent {
  id: string;
  name: string;
  type: string;
  action: string;
  icon: string;
  description: string;
  longDescription: string;
  version: string;
  developer: string;
  downloadCount: number;
  rating: number;
  reviews: Review[];
  categories: string[];
  releaseDate: string;
  lastUpdate: string;
  size: string;
  compatibility: string[];
  features: string[];
}
export interface Review {
  id: string;
  user: string;
  rating: number;
  comment: string;
  date: string;
}

const useSetUpWeb3Auth = () => {
  const [hookReady, setHookReady] = useState(false);
  const [web3AuthInitialized, setWeb3AuthInitialized] = useState(false);

  useEffect(() => {
    setHookReady(true);
  }, []);

  useEffect(() => {
    if (!web3Auth || !hookReady || web3AuthInitialized) return;
    const initWeb3Auth = async () => {
      await web3Auth.init();
      setWeb3AuthInitialized(true);
    };
    initWeb3Auth();
  }, [hookReady, web3AuthInitialized]);
  return { web3AuthInitialized };
};

const App: React.FC = () => {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const { web3AuthInitialized } = useSetUpWeb3Auth();
  const [web3AuthConnected, setWeb3AuthConnected] = useState(false);

  useEffect(() => {
    if (!web3AuthInitialized) return;
    setWeb3AuthConnected(web3Auth?.connected);
  }, [web3AuthInitialized]);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Save Telegram user data
  const saveTelegramUserData = (userData: TelegramWidgetLoginUser) => {
    localStorage.setItem("telegramUserData", JSON.stringify(userData));
  };

  // Helper function to get Telegram user data
  const getTelegramUserData = (): TelegramWidgetLoginUser | null => {
    const data = localStorage.getItem("telegramUserData");
    return data ? JSON.parse(data) : null;
  };

  const deleteTelegramUserDataIfExists = () => {
    localStorage.removeItem("telegramUserData");
  };

  const loginWithGoogle = useCallback(async () => {
    if (!web3Auth || !web3AuthInitialized || web3AuthConnected) return;
    await connectToWeb3Auth(LOGIN_PROVIDER.GOOGLE);
    setWeb3AuthConnected(web3Auth.connected);
  }, [web3AuthInitialized, web3AuthConnected]);

  const loginWithFacebook = useCallback(async () => {
    if (!web3Auth || !web3AuthInitialized || web3AuthConnected) return;
    await connectToWeb3Auth(LOGIN_PROVIDER.FACEBOOK);
    setWeb3AuthConnected(web3Auth.connected);
  }, [web3AuthInitialized, web3AuthConnected]);

  const loginWithDiscord = useCallback(async () => {
    if (!web3Auth || !web3AuthInitialized || web3AuthConnected) return;
    await connectToWeb3Auth(LOGIN_PROVIDER.DISCORD);
    setWeb3AuthConnected(web3Auth.connected);
  }, [web3AuthInitialized, web3AuthConnected]);

  const loginWithTelegram = useCallback(async () => {
    if (!web3Auth || !web3AuthInitialized || web3AuthConnected) return;

    const onTelegramLoginSuccess = async (
      userData: TelegramWidgetLoginUser
    ) => {
      const [resp, error] = await GetTokenForWeb3AuthTelegramLogin(userData);
      if (error) {
        toast.error(
          `Failed to login with Telegram. Please try again. - ${error}`
        );
        return;
      }
      await connectToWeb3Auth(LOGIN_PROVIDER.JWT, resp.jwt);
      saveTelegramUserData(userData);
      setWeb3AuthConnected(web3Auth.connected);
    };

    const handelTelegramLogin = () => {
      if (!telegramBotId) return;
      const telegramLoginWidget = window.Telegram.Login;
      telegramLoginWidget.auth(
        { bot_id: telegramBotId },
        (userData) => {
          onTelegramLoginSuccess(userData);
        },
        (error) => {
          toast.error(
            `Failed to login with Telegram. Please try again. - ${error}`
          );
        }
      );
    };
    handelTelegramLogin();
  }, [web3AuthInitialized, web3AuthConnected]);

  const handleLogin = useCallback(
    (loginType: TypeOfLogin) => {
      if (loginType === LOGIN_PROVIDER.GOOGLE) {
        loginWithGoogle();
      } else if (loginType === LOGIN_PROVIDER.FACEBOOK) {
        loginWithFacebook();
      } else if (loginType === LOGIN_PROVIDER.DISCORD) {
        loginWithDiscord();
      } else if (loginType === LOGIN_PROVIDER.JWT) {
        loginWithTelegram();
      } else {
        return;
      }
    },
    [loginWithGoogle, loginWithFacebook, loginWithDiscord, loginWithTelegram]
  );

  useEffect(() => {
    if (!web3Auth || !web3AuthInitialized || !web3AuthConnected) return;
    const setUserInfo = async () => {
      const userInfo = await web3Auth.getUserInfo();
      let userName = userInfo.name || "";
      let profilePictureURL = userInfo.profileImage || "";

      if (
        !userName ||
        (!profilePictureURL && userInfo.typeOfLogin === LOGIN_PROVIDER.JWT)
      ) {
        const telegramUser = getTelegramUserData();
        if (telegramUser) {
          const firstName = telegramUser.first_name || "";
          const lastName = telegramUser.last_name || "";
          userName = `${firstName} ${lastName}` || telegramUser.username || "";
          profilePictureURL = telegramUser.photo_url || "";
        }
      }
      const privateKey = (await getWeb3AuthPrivateKey()) || "";
      justXUser.setUserInfo(userName, profilePictureURL, privateKey);
    };

    setUserInfo();
  }, [web3AuthConnected]);

  const handleLogout = useCallback(() => {
    web3Auth?.logout();
    deleteTelegramUserDataIfExists();
    setWeb3AuthConnected(false);
  }, []);

  return (
    <ThemeProvider>
      <Routes>
        <>
          {web3AuthConnected ? (
            // Connected users see the main app
            <Route path="*" element={<MainApp onLogout={handleLogout} />} />
          ) : (
            // Non-connected users see the login page
            <>
              <Route
                path="/welcome"
                element={<Onboarding1 onNext={() => { }} />}
              />
              <Route
                path="/signup"
                element={<Web3AuthLoginPage onLoginButtonClick={handleLogin} />}
              />
              <Route path="*" element={<Navigate to="/welcome" />} />
            </>
          )}
        </>
      </Routes>
    </ThemeProvider>
  );
};

const MainApp: React.FC<{
  onLogout: () => void;
}> = ({ onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const { darkMode } = useTheme();

  // });
  const getSolanaAgent = (): Agent => ({
    id: "3",
    name: "Solana",
    type: "Blockchain",
    action: "Added",
    icon: solanaIcon,
    description:
      "Solana is a high-performance blockchain supporting builders around the world creating crypto apps that scale.",
    longDescription:
      "Solana is a decentralized blockchain built to enable scalable, user-friendly apps for the world. Its known for its fast transaction speeds and low costs.",
    version: "1.0",
    developer: "Solana Foundation",
    downloadCount: 0,
    rating: 5,
    reviews: [],
    categories: ["Blockchain", "DeFi", "Smart Contracts"],
    releaseDate: "2023-07-13",
    lastUpdate: "2023-07-13",
    size: "15MB",
    compatibility: ["All platforms"],
    features: ["Fast transactions", "Low fees"],
  });

  const [AllConnectAgents, setAllConnectAgents] = useState<Agent[]>(() => {
    const storedAgents = localStorage.getItem("AllConnectAgents");
    if (storedAgents) {
      const parsedAgents = JSON.parse(storedAgents);
      return parsedAgents.length > 0
        ? parsedAgents
        : [
          // getPersonalAgent(),
          getSolanaAgent(),
        ];
    } else {
      const defaultAgents = [
        // getPersonalAgent(),
        getSolanaAgent(),
      ];
      localStorage.setItem("AllConnectAgents", JSON.stringify(defaultAgents));
      return defaultAgents;
    }
  });

  // Helper function to get the Personal agent

  const handleSelectAgent = (agent: Agent) => {
    setAllConnectAgents((prevAgents) => {
      // Check if the agent is already selected
      if (prevAgents.some((a) => a.id === agent.id)) {
        return prevAgents; // Don't add if already present
      }

      const newAgents = [...prevAgents, agent];
      localStorage.setItem("AllConnectAgents", JSON.stringify(newAgents));
      return newAgents;
    });
  };

  const handleRemoveAgent = (agentId: string) => {
    setAllConnectAgents((prevAgents) => {
      const updatedAgents = prevAgents.filter((agent) => agent.id !== agentId);
      // Ensure at least the Personal agent remains
      const finalAgents =
        updatedAgents.length > 0 ? updatedAgents : [getSolanaAgent()];
      localStorage.setItem("AllConnectAgents", JSON.stringify(finalAgents));
      return finalAgents;
    });
  };

  const onToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const current_page = localStorage.getItem("activeTab");
  console.log("current", current_page);
  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  //   document.body.classList.toggle('dark');
  // };

  return (
    <div className={`app-container ${darkMode ? "dark" : ""}`}>
      <NewSidebar
        isOpen={isSidebarOpen}
        onToggleSidebar={onToggleSidebar}
        onLogout={onLogout}
      />
      <div
        className={`main-content content ${isSidebarOpen ? "expanded" : "collapsed"
          } ${isDarkMode ? "dark" : "light"}`}
      >
        <Routes>
          {/* <Route path="/home" element={<Dashboard  />} /> */}
          <Route
            path="/home"
            element={
              <Dashboard
              // onLogout={onLogout} profilePic={profilePic} AllConnectAgents={AllConnectAgents}
              // currentAccount={accounts[defaultAccountIndex]}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <ChatPage
                AllConnectAgents={AllConnectAgents}
                removeAgent={handleRemoveAgent}
              />
            }
          />

          <Route
            path="/marketplace"
            element={
              <MarketPlace
                onSelectAgent={handleSelectAgent}
                AllConnectAgents={AllConnectAgents} // Pass this prop
              />
            }
          />

          <Route path="/profile" element={<ProfilePage />} />
          {/* <Route path="/wallet" element={<WalletPage/>} /> */}
          <Route path="/wallet" element={<WalletPage />} />

          <Route path="/setting" element={<SettingsPage />} />

          <Route
            path="/*"
            element={
              current_page ? (
                <Navigate to={current_page} />
              ) : (
                <Navigate to="/home" />
              )
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const RootApp: React.FC = () => (
  <GoogleOAuthProvider clientId="939360077091-sqbk38cnuepbe96b455kcb61hjdstkep.apps.googleusercontent.com">
    <Router>
      <App />
    </Router>
  </GoogleOAuthProvider>
);

export default RootApp;
