import React, { useState, useEffect } from 'react';
import { FiSearch, FiCopy, FiExternalLink, FiTrendingUp, FiShield } from 'react-icons/fi';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

interface Token {
  name: string;
  address: string;
  icon: string;
  symbol: string;
  dailyVolume?: number;
}

interface Validator {
  name: string;
  address: string;
  icon: string;
  active_stake: string;
  commission: string;
}

const initialTokens: Token[] = [
  { name: "Wrapped SOL", address: "So11111111111111111111111111111111111111112", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png", symbol: "SOL", dailyVolume: 153666466.03406 },
  { name: "USD Coin", address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png", symbol: "USDC", dailyVolume: 103530119.777518 },
  { name: "USDT", address: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg", symbol: "USDT", dailyVolume: 15464507.7367518 },
  { name: "Moo Deng", address: "ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY", icon: "https://ipfs.io/ipfs/Qmf1g7dJZNDJHRQru7E7ENwDjcvu7swMUB6x9ZqPXr4RV2", symbol: "MOODENG", dailyVolume: 10805148.0522933 },
  { name: "dogwifhat", address: "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm", icon: "https://bafkreibk3covs5ltyqxa272uodhculbr6kea6betidfwy3ajsav2vjzyum.ipfs.nftstorage.link", symbol: "$WIF", dailyVolume: 9074163.6315473 },
  { name: "Popcat", address: "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr", icon: "https://arweave.net/A1etRNMKxhlNGTf-gNBtJ75QJJ4NJtbKh_UXQTlLXzI", symbol: "POPCAT", dailyVolume: 8129193.20400249 },
  { name: "Bonk", address: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263", icon: "https://arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I", symbol: "Bonk", dailyVolume: 6150131.58787016 },
  { name: "PayPal USD", address: "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo", icon: "https://424565.fs1.hubspotusercontent-na1.net/hubfs/424565/PYUSDLOGO.png", symbol: "PYUSD", dailyVolume: 5737907.15257582 },
  { name: "Jito Staked SOL", address: "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn", icon: "https://storage.googleapis.com/token-metadata/JitoSOL-256.png", symbol: "JitoSOL", dailyVolume: 5314495.45032328 },
  { name: "Raydium", address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png", symbol: "RAY", dailyVolume: 4000000 },
  { name: "Serum", address: "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png", symbol: "SRM", dailyVolume: 3500000 },
  { name: "Orca", address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png", symbol: "ORCA", dailyVolume: 3000000 },
  { name: "Marinade staked SOL", address: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png", symbol: "mSOL", dailyVolume: 2500000 },
  { name: "Step", address: "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png", symbol: "STEP", dailyVolume: 2000000 },
  { name: "Saber", address: "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1", icon: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.png", symbol: "SBR", dailyVolume: 1500000 },

];

const initialValidators: Validator[] = [
  { name: "Galaxy", address: "CvSb7wdQAFpHuSpTYTJnX5SYH4hCfQ9VuGnqrKaKwycB", icon: "https://bit.ly/3Q2D6ru", active_stake: "12,843,694.8041", commission: "5%" },
  { name: "Helius", address: "he1iusunGwqrNtafDtLdhsUQDFvo13z9sUa36PauBtk", icon: "https://helius-docs.s3.us-east-2.amazonaws.com/orange360x360.png", active_stake: "12,773,174.8309", commission: "0%" },
  { name: "Coinbase 02", address: "6D2jqw9hyVCpppZexquxa74Fn33rJzzBx38T58VucHx9", icon: "https://static-assets.coinbase.com/email/coinbase-logo-round.png", active_stake: "11,955,517.8344", commission: "8%" },
  { name: "Figment", address: "CcaHc2L43ZWjwCHART3oZoJvHLAe9hzT2DJNUpBzoTN1", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "10,117,629.214", commission: "7%" },
  { name: "Ledger by Figment", address: "26pV97Ce83ZQ6Kz9XT4td8tdoUFPTng8Fb8gPyc53dJx", icon: "https://s3.amazonaws.com/keybase_processed_uploads/3c47b62f3d28ecfd821536f69be82905_360_360.jpg", active_stake: "9,565,261.1435", commission: "7%" },
  { name: "P2P.org", address: "FKsC411dik9ktS6xPADxs4Fk2SCENvAiuccQHLAPndvk", icon: "https://cdn.p2p.org/p2p360_logo.png", active_stake: "7,890,907.0322", commission: "7%" },
  { name: "Everstake", address: "9QU2QSxhb24FUX3Tu2FpczXjpK3VYrvRudywSZaM29mF", icon: "https://s3.amazonaws.com/keybase_processed_uploads/fa01e6109b3fd4579c4bdd445d75ad05_360_360.jpg", active_stake: "6,657,247.9918", commission: "7%" },
  { name: "binance staking", address: "3N7s9zXMZ4QqvHQR15t5GNHyqc89KduzMP7423eWiD5g", icon: "", active_stake: "5,927,685.7087", commission: "8%" },
  { name: "Staking Facilities | MEV 🔥", address: "DumiCKHVqoCQKD8roLApzR5Fit8qGV5fVQsJV9sTZk4a", icon: "", active_stake: "5,445,863.1676", commission: "5%" },
  { name: "Twinstake", address: "Ak5BJzQe2R8qFuyYmaAFPjXuD7XPux3ZNTv52D7rfiqR", icon: "https://s3.amazonaws.com/keybase_processed_uploads/1c3b35f85ac2d3bea30f479373076e05_360_360.jpg", active_stake: "4,996,591.7746", commission: "10%" },
  { name: "Chorus One", address: "8CnJHf8vuyS2Gku2gJAJiEP6hc1cq2EJ7xdhGXSYjm2v", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "4,800,000.0000", commission: "8%" },
  { name: "Laine", address: "HXDtfHZb3B3ZEjPbX6ovoYBscF6iSVoMv8xT5RcQcR7G", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "4,700,000.0000", commission: "10%" },
  { name: "Shinobi Systems", address: "GwSX5rHosNxo2GsXPWoK8pDMxtEUbkQ4wG6ygu4Jx8kx", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "4,600,000.0000", commission: "8%" },
  { name: "Certus One", address: "8bQsWJx2pZ73dXb4pinWBbnSwgS7829RQZ4beeJX2pJR", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "4,500,000.0000", commission: "7%" },
  { name: "Staked", address: "AQPLz3imzkvLsUr5QqhHRTh7eJfKkJcDTGHHNaghFLr6", icon: "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg", active_stake: "4,400,000.0000", commission: "10%" },
];

const RightPanel: React.FC = () => {
  const [tokenSearch, setTokenSearch] = useState('');
  const [validatorSearch, setValidatorSearch] = useState('');
  const [tokens, setTokens] = useState<Token[]>(initialTokens);
  const [validators, setValidators] = useState<Validator[]>(initialValidators);
  const [activeTab, setActiveTab] = useState<'token' | 'validator'>('token');
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    setNoResults(false);
    try {
      if (activeTab === 'token') {
        const response = await axios.get(`https://blockchain-datadump.justx.ai/proxy
/search/name?name=${tokenSearch}`);
        const searchResults = response.data.map((token: any) => ({
          name: token.name,
          address: token.address,
          icon: token.logoURI,
          symbol: token.symbol,
          dailyVolume: token.daily_volume
        }));
        setTokens(searchResults);
        setNoResults(searchResults.length === 0);
      } else {
        const response = await axios.get(`https://blockchain-datadump.justx.ai/proxy
/search/validator_name?validator_name=${validatorSearch}`);
        const searchResults = response.data.map((validator: any) => ({
          name: validator.validator_name || 'Unknown',
          address: validator.vote_account,
          icon: validator.pictureURL,
          active_stake: validator.active_stake,
          commission: validator.commission
        }));
        setValidators(searchResults);
        setNoResults(searchResults.length === 0);
      }
    } catch (error) {
      console.error(`Error fetching ${activeTab}s:`, error);
      setNoResults(true);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    // Add a toast notification here
  };

  const renderSearchResults = (items: Token[] | Validator[], type: 'token' | 'validator') => {
    if (noResults) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="text-center py-4"
        >
          No data found. Try something else.
        </motion.div>
      );
    }

    return (
      <AnimatePresence>
        {items.map((item, index) => (
          <motion.div
            key={item.address}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
            className="flex  items-center justify-between p-3 hover:bg-gray-700 rounded-lg transition-colors duration-200"
          >
            <div className="flex items-center">
              <img src={item.icon || 'https://via.placeholder.com/40'} alt={item.name} className="w-8 h-8 mr-3 rounded-full" />
              <div>
                <p className="text-sm font-medium">{item.name}</p>
                <p className="text-xs text-gray-400">{item.address.slice(0, 8)}...{item.address.slice(-6)}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {type === 'token' && (
                <span className="text-xs text-gray-400">
                  Vol: ${(item as Token).dailyVolume?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </span>
              )}
              {type === 'validator' && (
                <span className="text-xs text-gray-400">
                  Stake: {(item as Validator).active_stake}
                </span>
              )}
              <button
                onClick={() => copyToClipboard(item.address)}
                className="p-1 hover:bg-gray-600 rounded-full transition-colors duration-200"
                title="Copy address"
              >
                <FiCopy size={16} />
              </button>
              <button
                onClick={() => window.open(`https://solscan.io/account/${item.address}`, '_blank')}
                className="p-1 hover:bg-gray-600 rounded-full transition-colors duration-200"
                title="View on explorer"
              >
                <FiExternalLink size={16} />
              </button>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    );
  };

  return (
    <div className="w-96 bg-gradient-to-br from-theme-dark-bg-from to-theme-dark-bg-to text-white p-6 h-[100%] my-auto r rounded-b-3xl rounded-t-3xl overflow-hidden shadow-2xl border-l border-b border-t border-theme-border backdrop-blur-lg">
      <div className="flex justify-between mb-6">
        <button
          className={`flex-1 py-2 rounded-l-lg ${activeTab === 'token' ? 'bg-teal-500' : 'bg-gray-700'}`}
          onClick={() => setActiveTab('token')}
        >
          <FiTrendingUp className="inline-block mr-2" /> Tokens
        </button>
        <button
          className={`flex-1 py-2 rounded-r-lg ${activeTab === 'validator' ? 'bg-teal-500' : 'bg-gray-700'}`}
          onClick={() => setActiveTab('validator')}
        >
          <FiShield className="inline-block mr-2" /> Validators
        </button>
      </div>

      <div className="relative mb-4">
        <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          placeholder={`Search ${activeTab}s...`}
          className="w-full pl-10 pr-20 py-3 bg-teal-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-200"
          value={activeTab === 'token' ? tokenSearch : validatorSearch}
          onChange={(e) => activeTab === 'token' ? setTokenSearch(e.target.value) : setValidatorSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <button
          onClick={handleSearch}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-teal-500 text-white px-3 py-1 rounded-md"
          disabled={isLoading}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>

      <div className="h-[calc(100%-100px)] overflow-hidden">
        <div className="h-full overflow-y-auto custom-scrollbar pr-2">
          <h2 className="text-xl font-bold mb-4">
            {isLoading ? 'Searching...' : `Top ${activeTab === 'token' ? 'Tokens' : 'Validators'}`}
          </h2>
          {isLoading ? (
            <div className="flex justify-center items-center h-40">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-500"></div>
            </div>
          ) : (
            renderSearchResults(activeTab === 'token' ? tokens : validators, activeTab)
          )}
        </div>
      </div>
    </div>
  );
};

export default RightPanel;