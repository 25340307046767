import profile1 from '../Assets/profilePics/Profile_Avatar1 (9).svg';

interface UserInterface {
  username: string;
  profilePictureURL: string;
  privateKey: string;
}

type UserInfoCallback = (userInfo: UserInterface) => void;

class JustXUser {
  private userInfo: UserInterface = {
    username: "JustX User",
    profilePictureURL: profile1,
    privateKey: "",
  };

  private listeners: UserInfoCallback[] = [];

  public setUserInfo(
    username: string,
    profilePictureURL: string,
    privateKey: string
  ): void {
    this.userInfo = {
      username,
      profilePictureURL,
      privateKey,
    };
    // Notify all listeners when user info changes
    this.notifyListeners();
  }

  public getUserInfo(): UserInterface {
    return this.userInfo;
  }

  public onUserInfoChange(callback: UserInfoCallback): () => void {
    this.listeners.push(callback);
    // Immediately call callback if we already have user info
    if (this.userInfo.username || this.userInfo.profilePictureURL) {
      callback(this.userInfo);
    }
    // Return cleanup function
    return () => {
      this.listeners = this.listeners.filter((cb) => cb !== callback);
    };
  }

  private notifyListeners(): void {
    this.listeners.forEach((callback) => callback(this.userInfo));
  }
}

export const justXUser = new JustXUser();
