import React, { createContext, useState, useContext, useEffect } from 'react';

interface ThemeColors {
  bg: string;
  cardBg: string;
  text: string;
  inputBg: string;
  secondaryText: string;
  accent: string;
  border: string;
  buttonBg: string;
  buttonText: string;
}

interface ThemeContextType {
  darkMode: boolean;
  toggleDarkMode: () => void;
  colors: ThemeColors;
}

const lightColors: ThemeColors = {
  bg: '#F8F9FA',
  cardBg: '#FFFFFF',
  text: '#000000',
  inputBg: '#FFFFFF',
  secondaryText: '#6C757D',
  accent: '#00FFF2',
  border: '#E9ECEF',
  buttonBg: '#4A90E2',
  buttonText: '#FFFFFF',
};

const darkColors: ThemeColors = {
  bg: '#000E10',
  cardBg: '#001417',
  text: '#FFFFFF',
  inputBg: '#001417',
  secondaryText: '#B9B9B9',
  accent: '#00FFF2',
  border: '#16E1CC',
  buttonBg: '#4A90E2',
  buttonText: '#FFFFFF',
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : true;
  });

  const colors = darkMode ? darkColors : lightColors;

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode, colors }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};