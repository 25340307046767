import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Toaster } from "sonner";
import background from "../../Assets/Image/Rectangle 40256.png";
import { BorderBeam } from "../../magicui/border-beam";
import head from "../../Assets/Image/Group 1321314365.svg";
import pill from "../../Assets/Image/Group 1321314367.svg";
import Logo from "../../Assets/Image/justxlogo.png";
import "./Web3AuthLogin.scss"; 
import { TypeOfLogin, LOGIN_PROVIDER } from "@web3auth/auth-adapter";

const Web3AuthLoginPage: React.FC<{onLoginButtonClick: (
  loginType: TypeOfLogin
) => void}> = ({ onLoginButtonClick }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const pageVariants = {
    initial: { opacity: 0, x: "100%" },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  const GoogleSignInButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-[80%] mx-auto bg-white/10 text-white font-semibold py-2 px-4 border border-white/15 rounded-lg shadow-sm flex items-center justify-center space-x-2 mb-4"
      onClick={() => onLoginButtonClick(LOGIN_PROVIDER.GOOGLE)}
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google logo"
        className="w-5 h-5"
      />
      <span>Continue with Google</span>
    </motion.button>
  );

  const FacebookSignInButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-[80%] mx-auto bg-white/10 text-white font-semibold py-2 px-4 border border-white/15 rounded-lg shadow-sm flex items-center justify-center space-x-2 mb-4"
      onClick={() => onLoginButtonClick(LOGIN_PROVIDER.FACEBOOK)}
    >
      <img
        src="https://static.xx.fbcdn.net/rsrc.php/v3/yG/r/pENh3y_2Pnw.png"
        alt="Facebook logo" 
        className="w-5 h-5"
      />
      <span>Continue with Facebook</span>
    </motion.button>
  );

  const DiscordSignInButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-[80%] mx-auto bg-white/10 text-white font-semibold py-2 px-4 border border-white/15 rounded-lg shadow-sm flex items-center justify-center space-x-2 mb-4"
      onClick={() => onLoginButtonClick(LOGIN_PROVIDER.DISCORD)}
    >
      <img
        src="https://img.icons8.com/color/512/discord-logo.png"
        alt="Discord logo" 
        className="w-5 h-5"
      />
      <span>Continue with Discord</span>
    </motion.button>
  );

  const TelegramSignInButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-[80%] mx-auto bg-white/10 text-white font-semibold py-2 px-4 border border-white/15 rounded-lg shadow-sm flex items-center justify-center space-x-2 mb-4"
      onClick={() => onLoginButtonClick(LOGIN_PROVIDER.JWT)}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
        alt="Telegram logo" 
        className="w-5 h-5"
      />
      <span>Continue with Telegram</span>
    </motion.button>
  );

  return (
    <>
      <motion.div
        className="flex min-h-screen bg-gradient-to-br from-teal-950 via-[#031316]/80 to-black bg-clip-padding  overflow-hidden"
        initial="initial"
        animate="in"
        exit="out"
      >
        <Toaster richColors closeButton position="top-center" />

        <div className="flex flex-col justify-center lg:flex-row min-h-screen glass-effect text-slate-900 w-full">
          <motion.div
            className="relative w-full lg:w-1/2 h-full flex items-center justify-center py-10 px-4 sm:px-6 lg:px-8"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5 }}
              className="flex justify-center items-center w-full sm:w-[80%] lg:w-[60%] h-full"
            >
              <motion.img
                src={Logo}
                alt="JustX Logo"
                className="absolute top-4 left-1  w-24 sm:top-8 sm:w-32 z-20"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              />

              <div className="relative rounded-3xl flex flex-col w-full justify-center">
                <AnimatePresence mode="wait">
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                    className="relative w-full h-full flex fade-in-right"
                  >
                    <motion.div
                      className="flex justify-center flex-col w-full"
                      initial="hidden"
                      animate="visible"
                    >
                      <GoogleSignInButton />
                      <FacebookSignInButton />
                      <DiscordSignInButton />
                      <TelegramSignInButton />
                    </motion.div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </motion.div>
          </motion.div>

          <div id="google-one-tap-button" className="mb-4"></div>

          {/* Right side box */}
          {!isMobile && (
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
              className="hidden lg:flex justify-center items-center p-4 md:p-6 w-full lg:w-[45%] h-screen relative"
            >
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="bg-[#000405] relative rounded-md shadow-lg px-8 w-full h-full flex fade-in-right overflow-hidden items-center justify-center"
              >
                {/* Network Visualization Background */}
                <div className="absolute inset-0 opacity-10">
                  <img
                    src={background}
                    alt="Background"
                    className="object-cover w-full h-full"
                  />
                </div>

                <motion.div
                  className="relative w-400 h-96"
                  animate={{
                    y: [0, -10, 0],
                    rotateY: [0, 5, 0],
                  }}
                  transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <img
                    src={head}
                    alt="3D Head Projection"
                    className="w-full h-full mt-20"
                  />
                </motion.div>

                {/* Floating Elements */}
                <motion.div
                  className="absolute top-1/4 left-8 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                  whileHover={{ scale: 1.1 }}
                  animate={{
                    x: [0, 10, 0],
                    y: [0, -5, 0],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <img src={pill} alt="Floating Elements" />
                  AI Insights
                </motion.div>
                <motion.div
                  className="absolute top-16 right-1/3 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                  whileHover={{ scale: 1.1 }}
                  animate={{
                    x: [0, -10, 0],
                    y: [0, 5, 0],
                  }}
                  transition={{
                    duration: 4.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <img src={pill} alt="Floating Elements" />
                  Secure Transactions
                </motion.div>
                <motion.div
                  className="absolute top-1/3 right-12 bg-gray-800 gap-3 text-gray-300 px-4 py-2 rounded-md border-teal-500 border flex items-center"
                  whileHover={{ scale: 1.1 }}
                  animate={{
                    x: [0, 5, 0],
                    y: [0, -10, 0],
                  }}
                  transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <img src={pill} alt="Floating Elements" />
                  Smart Contract
                </motion.div>
                <BorderBeam size={250} duration={12} delay={9} />
              </motion.div>
            </motion.div>
          )}
        </div>
        <Toaster richColors closeButton position="top-center" />
      </motion.div>
    </>
  );
};

export default Web3AuthLoginPage;
