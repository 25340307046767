import React from 'react';
import { FiSearch, FiMail, FiUser, FiSettings, FiArrowRight, FiMenu } from 'react-icons/fi';
import solanaLogo from "../../Assets/Image/solana-logo.png"
import { Link } from 'react-router-dom';
const Header: React.FC = () => (
  <header className="bg-[#131720] p-4 flex justify-between items-center">
    <h1 className="text-xl sm:text-2xl font-bold text-white">Dashboard</h1>
    <div className="hidden md:flex space-x-6">
      <FiSearch className="text-gray-400 hover:text-white cursor-pointer" size={24} />
      <FiMail className="text-gray-400 hover:text-white cursor-pointer" size={24} />
      <FiUser className="text-gray-400 hover:text-white cursor-pointer" size={24} />
      <FiSettings className="text-gray-400 hover:text-white cursor-pointer" size={24} />
    </div>
    <FiMenu className="md:hidden text-gray-400 hover:text-white cursor-pointer" size={24} />
  </header>
);

const Banner: React.FC = () => (
  <div className="relative h-48 sm:h-56 md:h-64 rounded-lg overflow-hidden mb-6 bg-[#0F2027]">
    <div className="absolute inset-0 bg-gradient-to-r from-[#203A43] to-[#2C5364] opacity-50"></div>
    <div className="absolute inset-0 flex flex-col justify-center px-4 sm:px-8">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-2">
        Your personalised gateway to the Multi-chain
      </h2>
      <p className="text-base sm:text-lg md:text-xl text-gray-300">
        Manage your agents across multiple blockchains and AI systems
      </p>
    </div>
  </div>
);

const Card: React.FC<{ children: React.ReactNode; glowColor: string; className?: string }> = ({ children, glowColor, className = "" }) => (
  <div className={`bg-[#171C24] rounded-lg p-4 relative overflow-hidden ${className}`}>
    <div className={`absolute inset-0 ${glowColor} opacity-20 blur-xl`}></div>
    <div className="relative z-10">{children}</div>
  </div>
);

const DashboardPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-[#0B0E13] text-white">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <Banner />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card glowColor="bg-gradient-to-r from-purple-400 to-pink-600" className="col-span-1 sm:col-span-2">
            <h3 className="text-xl font-bold mb-4">Recent Agents</h3>
            <div className="flex items-center justify-between bg-[#1E2530] rounded p-3 mb-4">
              <div className="flex items-center">
                <img src={solanaLogo} alt="Solana" className="w-8 h-8 sm:w-10 sm:h-10 mr-3" />
                <div>
                  <h4 className="font-bold">Sol</h4>
                  <p className="text-xs text-gray-400">Solana</p>
                  <div className="flex mt-1">
                    <span className="text-xs bg-[#2A3142] px-2 py-1 rounded mr-2">Blockchain</span>
                    <span className="text-xs bg-[#2A3142] px-2 py-1 rounded">Agent</span>
                  </div>
                </div>
              </div>
              <span className="text-yellow-400">★</span>
            </div>
            <p className="text-sm text-gray-300 mb-4">Solana Agent leverages its low-latency blockchain and smart...</p>
            <Link to="/chat">
            <button className="w-full bg-[#1E2530] text-[#4FFFB0] border border-[#4FFFB0] py-2 rounded-lg hover:bg-[#4FFFB0] hover:text-[#1E2530] transition-colors">
              Connect to Agent
            </button>
            </Link>
          </Card>
          
          <Card glowColor="bg-gradient-to-r from-blue-400 to-teal-600" className="col-span-1 sm:col-span-2 row-span-2">
            <div className="flex flex-col h-full justify-between">
              <div>
                <h3 className="text-xl font-bold mb-2">Simplifies user interactions</h3>
                <p className="text-2xl sm:text-3xl font-bold mb-4">We're here to assist you in safeguarding your digital assets</p>
              </div>
              <div className="text-right">
                <FiArrowRight size={24} className="text-[#4FFFB0] inline-block" />
              </div>
            </div>
          </Card>

          <Card glowColor="bg-gradient-to-r from-green-400 to-blue-500" className="col-span-1 sm:col-span-2">
            <h3 className="text-xl font-bold mb-2">Customized solutions</h3>
            <p className="text-sm">an AI-Agent based omnichain crypto SuperApp that simplifies user interactions across multiple chains, smart contracts, protocols, and bridges.</p>
          </Card>

          <Card glowColor="bg-gradient-to-r from-yellow-400 to-orange-500" className="col-span-1 sm:col-span-2 row-span-2">
            <div className="flex flex-col sm:flex-row justify-between h-full">
              <div className="mb-4 sm:mb-0 sm:mr-4">
                <h3 className="text-xl font-bold mb-2">Launch your AI Agent today</h3>
                <p className="text-sm mb-4">Deploy Specialized AI Agents designed to autonomously perform tasks across multiple chains focus on innovation and let your agent handle the blockchain complexities with JustX.</p>
                <button className="bg-[#4FFFB0] text-black py-2 px-4 rounded-lg flex items-center">
                  Launch Agent <FiArrowRight className="ml-2" />
                </button>
              </div>
              <img src="/path-to-ai-agent-image.png" alt="AI Agent" className="w-full sm:w-1/3 h-auto object-contain" />
            </div>
          </Card>

          <Card glowColor="bg-gradient-to-r from-red-400 to-pink-500">
            <h3 className="text-lg font-bold mb-2">we stay up-to-date</h3>
            <p className="text-sm">Our focus is on staying ahead of the curve with the newest technologies</p>
          </Card>

          <Card glowColor="bg-gradient-to-r from-indigo-400 to-purple-500">
            <p className="text-sm">SuperApp that simplifies user interactions across multiple chains, smart contracts, protocols, and bridges.</p>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;