// SettingsPage.tsx
import React, { useState } from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import GeneralSettings from './GeneralSettings';
import AdvancedSettings from './AdvancedSettings';
import {useTheme} from '../../contexts/ThemeContext'

const SettingsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'general' | 'advanced'>('general');
  const [theme, setTheme] = useState<'dark' | 'light'>('dark');
  const { darkMode } = useTheme();
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
    // Implement theme change logic here
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gradient-to-tl from-theme-dark-bg-from to-theme-dark-bg-to  text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="container mx-auto px-7 py-8">
        <h1 className="text-4xl font-bold mb-8">Settings</h1>
        
        {/* Tabs */}
        <div className="flex mb-8">
          <button
            className={`mr-8 pb-2 ${activeTab === 'general' ? 'text- border-b-2 border-teal-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab('general')}
          >
            <span className="text-xl font-bold">⚙️ General</span> 
          </button>
          <button
            className={`pb-2 ${activeTab === 'advanced' ? 'text-teal-500 border-b-2 border-teal-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab('advanced')}
          >
            <span className="text-xl font-bold">🔧 Advanced</span> 
          </button>
        </div>

        {/* Theme toggle */}
        

        {/* Content */}
        {activeTab === 'general' ? (
          <GeneralSettings/>
        ) : (
          <AdvancedSettings/>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;