// src/components/ContactForm.tsx

import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

interface ContactFormProps {
  onAddContact: (contact: { name: string; publicKey: string; relation: string }) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onAddContact }) => {
  const { colors } = useTheme();
  const [name, setName] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [relation, setRelation] = useState('');
  const [publicKeyError, setPublicKeyError] = useState('');

  const validatePublicKey = (key: string) => {
    const regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
    return regex.test(key);
  };

  const handlePublicKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPublicKey(value);
    if (!validatePublicKey(value)) {
      setPublicKeyError('Invalid Solana public key format');
    } else {
      setPublicKeyError('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validatePublicKey(publicKey)) {
      onAddContact({ name, publicKey, relation });
      setName('');
      setPublicKey('');
      setRelation('');
      setPublicKeyError('');
    } else {
      setPublicKeyError('Please enter a valid Solana public key');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8">
      <div className="space-y-4">
        <div>
          <label htmlFor="contactName" className="block mb-1" style={{ color: colors.accent }}>Name</label>
          <input
            type="text"
            id="contactName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
            required
          />
        </div>
        <div>
          <label htmlFor="publicKey" className="block mb-1" style={{ color: colors.accent }}>Public Key</label>
          <input
            type="text"
            id="publicKey"
            value={publicKey}
            onChange={handlePublicKeyChange}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
            required
          />
          {publicKeyError && <p className="text-red-500 text-sm mt-1">{publicKeyError}</p>}
        </div>
        <div>
          <label htmlFor="relation" className="block mb-1" style={{ color: colors.accent }}>Relation</label>
          <input
            type="text"
            id="relation"
            value={relation}
            onChange={(e) => setRelation(e.target.value)}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
            required
          />
        </div>
      </div>
      <button
        type="submit"
        className="mt-4 px-6 py-2 rounded font-semibold"
        style={{ backgroundColor: colors.buttonBg, color: colors.buttonText }}
      >
        Add Contact
      </button>
    </form>
  );
};

export default ContactForm;