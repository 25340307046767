const requiredEnvVars = [
    "REACT_APP_SOLANA_CHAIN_ID",
    "REACT_APP_SOLANA_RPC_TARGET",
    "REACT_APP_SOLANA_DISPLAY_NAME",
    "REACT_APP_SOLANA_BLOCK_EXPLORER",
    "REACT_APP_SOLANA_TICKER",
    "REACT_APP_SOLANA_TICKER_NAME",
    "REACT_APP_WEB3AUTH_CLIENT_ID",
    "REACT_APP_WEB3AUTH_NETWORK",
    "REACT_APP_WEB3AUTH_TELEGRAM_VERIFIER",
    "REACT_APP_JUSTX_UTILITY_URL",
    "REACT_APP_TELEGRAM_BOT_ID",

];

requiredEnvVars.forEach((varName) => {
    if (!process.env[varName]) {
        throw new Error(`Environment variable ${varName} is missing`);
    }
});

export const solanaChainId = process.env.REACT_APP_SOLANA_CHAIN_ID;
export const solanaRpcTarget = process.env.REACT_APP_SOLANA_RPC_TARGET;
export const solanaDisplayName = process.env.REACT_APP_SOLANA_DISPLAY_NAME;
export const solanaBlockExplorer = process.env.REACT_APP_SOLANA_BLOCK_EXPLORER;
export const solanaTicker = process.env.REACT_APP_SOLANA_TICKER;
export const solanaTickerName = process.env.REACT_APP_SOLANA_TICKER_NAME;

export const web3AuthClientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;
export const web3AuthTelegramVerifier = process.env.REACT_APP_WEB3AUTH_TELEGRAM_VERIFIER;
export const web3AuthNetwork = process.env.REACT_APP_WEB3AUTH_NETWORK;

export const justxUtilityURL = process.env.REACT_APP_JUSTX_UTILITY_URL;
export const telegramBotId = process.env.REACT_APP_TELEGRAM_BOT_ID; 