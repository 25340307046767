// src/utils/profilePicManager.ts

// Import all profile pictures
import profile1 from '../Assets/profilePics/Profile_Avatar1 (9).svg';
import profile2 from '../Assets/profilePics/Profile_Avatar1 (10).svg';
import profile3 from '../Assets/profilePics/Profile_Avatar1 (11).svg';
import profile4 from '../Assets/profilePics/Profile_Avatar1 (12).svg';
import profile5 from '../Assets/profilePics/Profile_Avatar1 (15).svg';
import profile6 from '../Assets/profilePics/Profile_Avatar1 (27).svg';
import profile7 from '../Assets/profilePics/Profile_Avatar1 (31).svg';
import profile8 from '../Assets/profilePics/Profile_Avatar1 (48).svg';
import profile9 from '../Assets/profilePics/Profile_Avatar1 (50).svg';
import profile10 from '../Assets/profilePics/Profile_Avatar1 (52).svg';
import profile11 from '../Assets/profilePics/Profile_Avatar1 (54).svg';
import profile12 from '../Assets/profilePics/Profile_Avatar1 (56).svg';
import profile13 from '../Assets/profilePics/Profile_Avatar1 (59).svg';
import profile14 from '../Assets/profilePics/Profile_Avatar1 (61).svg';

// ... import more profile pictures as needed
// import { toast } from 'sonner';
// Array of all profile picture imports
const profilePics = [profile1, profile2, profile3,profile4,profile5,profile6,profile7,profile8,profile9,profile10,profile11,profile12,profile13,profile14];

export const getOrGenerateProfilePic = (): string => {
  const storedProfilePic = localStorage.getItem('userProfilePic');
  
  if (storedProfilePic) {
    return storedProfilePic;
  }

  const randomIndex = Math.floor(Math.random() * profilePics.length);
  const newProfilePic = profilePics[randomIndex];
  
  localStorage.setItem('userProfilePic', newProfilePic);
  return newProfilePic;
};

export const updateProfilePic = (newPicUrl: string): boolean => {
  try {
    localStorage.setItem('userProfilePic', newPicUrl);
    return true;
  } catch (error) {
    console.error('Failed to save profile picture:', error);
    return false;
  }
};