// Toggle.tsx
import React from 'react';

interface ToggleProps {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ enabled, setEnabled }) => {
  return (
    <div
      className={`relative inline-block w-14 h-8 transition-colors duration-200 ease-in-out rounded-full cursor-pointer ${
        enabled ? 'bg-theme-button-bg' : 'border-theme-dark-border border'
      }`}
      onClick={() => setEnabled(!enabled)}
    >
      <div
        className={`absolute left-1 top-1 w-6 h-6 transition-transform duration-200 ease-in-out bg-theme-border rounded-full shadow-md ${
          enabled ? 'transform translate-x-6' : ''
        }`}
      ></div>
    </div>
  );
};

export default Toggle;