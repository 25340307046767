import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import tourgif from '../../Assets/Image/IntroTour.gif';
// Define the structure for each tour step
interface TourStep {
  title: string;
  description: string;

  // image: string;
}

// Define the tour steps
const tourSteps: TourStep[] = [

  {
    title: 'Wallet Integration',
    description: 'Manage multiple blockchain wallets and crypto assets directly within the chat interface.',
    // image: '../../Assets/Image/02_ai_brain_chip-removebg-preview.png'
  },
  {
    title: 'Real-time Balance Display',
    description: 'View your wallet balances updated in real-time.',
    // image: '/images/balance-display.png'
  },
  {
    title: 'Transaction Capabilities',
    description: 'Send transactions directly from the chat interface for easy blockchain interactions.',
    // image: '/images/transactions.png'
  },
  {
    title: 'Chat History',
    description: 'Access a history of chat sessions to revisit previous conversations.',
    // image: '/images/chat-history.png'
  },
  {
    title: 'Code Highlighting',
    description: 'Share and read code snippets with syntax highlighting for better readability.',
    // image: '/images/code-highlighting.png'
  },
  {
    title: 'File Attachments',
    description: 'Attach files to your messages to share various types of data.',
    // image: '/images/file-attachments.png'
  },
  {
    title: 'Voice Input',
    description: 'Use voice input to dictate messages instead of typing.',
    // image: '/images/voice-input.png'
  },
  {
    title: 'Expandable Images',
    description: 'Click on images to view them in a larger format.',
    // image: '/images/expandable-images.png'
  },
  {
    title: 'Responsive Design',
    description: 'Enjoy a seamless experience across different screen sizes and devices.',
    // image: '/images/responsive-design.png'
  },
  {
    title: 'Quick Actions',
    description: 'Use predefined quick actions for common tasks to speed up interactions.',
    // image: '/images/quick-actions.png'
  },
  
  {
    title: 'Transaction Status Updates',
    description: 'Receive real-time updates on the status of your blockchain transactions.',
    // image: '/images/transaction-status.png'
  },
  {
    title: 'Markdown Support',
    description: 'Use Markdown formatting for rich text messages in the chat.',
    // image: '/images/markdown-support.png'
  }
];

interface FeatureTourProps {
  onComplete: () => void;
}

const FeatureTour: React.FC<FeatureTourProps> = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    // Check if the user has seen the tour before
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (hasSeenTour) {
      onComplete();
    }
  }, [onComplete]);

  const handleNext = () => {
    if (currentStep < tourSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleComplete();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleComplete = () => {
    localStorage.setItem('hasSeenTour', 'true');
    onComplete();
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
          className="bg-gradient-to-br from-gray-800 via-green-800 to-lime-700 rounded-2xl max-w-lg w-full mx-4"
        >
          
          <img
            src={tourgif}
            alt="tour gif"
            className="w-full h-64 object-cover rounded-tr-2xl rounded-tl-2xl  "
          />
          <div className='p-5'>

          
          <h2 className="text-white text-2xl font-bold mb-2">{tourSteps[currentStep].title}</h2>
          <p className="text-gray-300/60 mb-4 text-base font-normal">{tourSteps[currentStep].description}</p>
          <div className="flex justify-between items-center">
            <button
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className="px-8 py-2 border-gray-200 border text-gray-300 hover:text-gray-800 rounded-lg disabled:opacity-50 transition-colors hover:bg-gray-300"
            >
              Previous
            </button>
            <div className="text-sm text-gray-400">
              {currentStep + 1} / {tourSteps.length}
            </div>
            <button
              onClick={handleNext}
              className="px-8 py-2 bg-gray-200/50 text-white rounded-lg transition-colors  hover:bg-green-950"
            >
              {currentStep === tourSteps.length - 1 ? 'Finish' : 'Next'}
            </button>
          </div>
          <button
            onClick={handleComplete}
            className="mt-4 text-xs text-gray-400/50 hover:text-gray-300 transition-colors "
          >
            Skip Tour
          </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FeatureTour;