import { Web3AuthNoModal as Web3Auth } from "@web3auth/no-modal";
import {
  CHAIN_NAMESPACES,
  UX_MODE,
  WALLET_ADAPTERS,
  WEB3AUTH_NETWORK_TYPE,
} from "@web3auth/base";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";
import {
  AuthAdapter,
  LOGIN_PROVIDER,
  TypeOfLogin,
} from "@web3auth/auth-adapter";
import {
  solanaChainId,
  solanaRpcTarget,
  solanaDisplayName,
  solanaBlockExplorer,
  solanaTicker,
  solanaTickerName,
  web3AuthClientId,
  web3AuthNetwork,
  web3AuthTelegramVerifier,
} from "../config";

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.OTHER,
  chainId: solanaChainId || "",
  rpcTarget: solanaRpcTarget || "",
  displayName: solanaDisplayName || "",
  blockExplorerUrl: solanaBlockExplorer || "",
  ticker: solanaTicker || "",
  tickerName: solanaTickerName || "",
  logo: "",
};

const privateKeyProvider = new CommonPrivateKeyProvider({
  config: { chainConfig },
});

// For custom login add loginConfig with the required parameters
// For more details refer to the docs - https://web3auth.io/docs/auth-provider-setup/verifiers
const authAdapter = new AuthAdapter({
  adapterSettings: {
    uxMode: UX_MODE.REDIRECT,
    loginConfig: {
      jwt: {
        verifier: web3AuthTelegramVerifier || "",
        typeOfLogin: "jwt",
        clientId: web3AuthClientId || "",
      },
    },
  },
});

const web3Auth = new Web3Auth({
  clientId: web3AuthClientId || "",
  privateKeyProvider: privateKeyProvider,
  web3AuthNetwork: web3AuthNetwork as WEB3AUTH_NETWORK_TYPE,
});
web3Auth.configureAdapter(authAdapter);

const connectToWeb3Auth = async (
  typeOfLogin: TypeOfLogin,
  idToken?: string
): Promise<boolean> => {
  try {
    let extraLoginOptions = {};
    if (typeOfLogin === LOGIN_PROVIDER.JWT) {
      if (!idToken) {
        throw new Error("idToken is required for jwt login");
      }
      extraLoginOptions = {
        id_token: idToken,
        verifierIdField: "sub",
      };
    }
    const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.AUTH, {
      loginProvider: typeOfLogin,
      extraLoginOptions: extraLoginOptions,
    });
    if (!web3authProvider) {
      return false;
    }
    await web3authProvider.request({ method: "private_key" });
    return true;
  } catch (error) {
    return false;
  }
};

const getWeb3AuthPrivateKey = async (): Promise<string | null> => {
  if (!web3Auth.connected) return null;
  if (!web3Auth.provider) return null;
  try {
    const privateKey = await web3Auth.provider.request({
      method: "private_key",
    });
    return (privateKey as string) || null;
  } catch (error) {
    return null;
  }
};

export { web3Auth, connectToWeb3Auth, getWeb3AuthPrivateKey };
