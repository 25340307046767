// src/components/PersonalInfoForm.tsx

import React, { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

interface User {
  name: string;
  email: string;
  phone: string;
  bio: string;
}

interface PersonalInfoFormProps {
  user: User;
  onSave: (updatedInfo: Partial<User>) => void;
}

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({ user, onSave }) => {
  const { colors } = useTheme();
  const [formData, setFormData] = useState<User>(user);

  useEffect(() => {
    setFormData(user);
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
    setFormData({ name: '', email: '', phone: '', bio: '' }); // Reset form fields
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8">
      <h2 className="text-2xl font-semibold mb-4" style={{ color: colors.accent }}>Personal Information</h2>
      <div className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1" style={{ color: colors.accent }}>Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1" style={{ color: colors.accent }}>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
          />
        </div>
        <div>
          <label htmlFor="phone" className="block mb-1" style={{ color: colors.accent }}>Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
          />
        </div>
        <div>
          <label htmlFor="bio" className="block mb-1" style={{ color: colors.accent }}>Bio</label>
          <textarea
            id="bio"
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            className="w-full p-2 rounded"
            style={{ backgroundColor: colors.inputBg, color: colors.text, border: `1px solid ${colors.border}` }}
            rows={4}
          />
        </div>
      </div>
      <button
        type="submit"
        className="mt-4 px-6 py-2 rounded font-semibold"
        style={{ backgroundColor: colors.buttonBg, color: colors.buttonText }}
      >
        Save Changes
      </button>
    </form>
  );
};

export default PersonalInfoForm;