import React from 'react';
import { motion } from 'framer-motion';
import { FiX, FiAlertTriangle, FiCopy, FiRefreshCw } from 'react-icons/fi';

interface ZeroBalanceWarningProps {
  address: string;
  onClose: () => void;
  onRefresh: () => void;
}

const ZeroBalanceWarning: React.FC<ZeroBalanceWarningProps> = ({ address, onClose, onRefresh }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    // toast.success('Address copied to clipboard');
    // You can add a toast notification here to inform the user that the address has been copied
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FiX size={24} />
        </button>
        <div className="flex items-center mb-4 text-yellow-500">
          <FiAlertTriangle size={24} className="mr-2" />
          <h2 className="text-xl font-bold">Low Balance Warning</h2>
        </div>
        <p className="text-gray-600 mb-4">
          Your Solana wallet balance is currently zero. To use the chat and perform transactions, 
          please add some SOL to your wallet.
        </p>
        <div className="bg-gray-100 p-3 rounded-lg mb-4 flex items-center justify-between">
          <span className="text-sm font-medium text-gray-700 truncate">{address}</span>
          <button
            onClick={copyToClipboard}
            className="ml-2 text-blue-500 hover:text-blue-600"
            title="Copy address"
          >
            <FiCopy size={18} />
          </button>
        </div>
        <div className="flex justify-between items-center">
          <a
            href={`https://solscan.io/account/${address}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-600 text-sm"
          >
            View on Solscan
          </a>
          {/* <button
            onClick={onRefresh}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors mr-2"
          >
            <FiRefreshCw className="inline mr-2" />
            Refresh Balance
          </button> */}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            I Understand
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ZeroBalanceWarning;