// GeneralSettings.tsx
import React, { useState } from 'react';
import Toggle from './Toggle';

import {useTheme} from '../../contexts/ThemeContext'


const GeneralSettings: React.FC= () => {
  const [twoFactor, setTwoFactor] = useState(false);
  const { darkMode } = useTheme();
  const handleChangePassword = () => {
    // Implement password change logic
    console.log('Change password clicked');
  };

  const handleViewHistory = () => {
    // Implement view login history logic
    console.log('View login history clicked');
  };

  return (
    <div className=''>
      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h2 className="text-xl font-semibold">Two-Factor Authentication</h2>
            <p className="text-gray-500 text-sm">Add an extra layer of security to your account</p>
          </div>
          <Toggle enabled={twoFactor} setEnabled={setTwoFactor} />
        </div>
      </div>

      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h2 className="text-xl font-semibold">Password Reset</h2>
            <p className="text-gray-500 text-sm">Change your account password</p>
          </div>
          <button
            className="bg-theme-button-bg text-white px-4 py-2 rounded"
            onClick={handleChangePassword}
          >
            Change Password
          </button>
        </div>
      </div>

      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h2 className="text-xl font-semibold">Login History</h2>
            <p className="text-gray-500 text-sm">View your recent login activity</p>
          </div>
          <button
            className="bg-theme-button-bg text-white px-4 py-2 rounded"
            onClick={handleViewHistory}
          >
            View History
          </button>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Security Score</h2>
        <p className="text-gray-500 text-sm mb-2">Your account security score</p>
        <div className="bg-gray-700 h-4 rounded-full">
          <div
            className="bg-yellow-500 h-4 rounded-full"
            style={{ width: '85%' }}
          ></div>
        </div>
        <p className="text-right text-yellow-500 font-semibold mt-1">85%</p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Blockchain Security</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={`p-4 rounded-lg border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}>
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-semibold">Multi-Signature Wallet</h3>
                <p className="text-sm text-gray-500">Enable multi-sig for enhanced security</p>
              </div>
              <Toggle enabled={false} setEnabled={() => {}} />
            </div>
          </div>
          <div className={`p-4 rounded-lg border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}>
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-semibold">Hardware Wallet</h3>
                <p className="text-sm text-gray-500">Connect your hardware wallet</p>
              </div>
              <button className="bg-theme-button-bg text-white px-4 py-2 rounded">
                Connect Wallet
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-4">Recent Security Events</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className={`p-4 rounded-lg border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}>
            <div className="flex items-center">
              <span className="text-red-500 mr-2">🛑</span>
              <div>
                <h3 className="font-semibold">Failed login attempt</h3>
                <p className="text-sm text-gray-500">2023-07-15 14:30</p>
              </div>
            </div>
          </div>
          <div className={`p-4 rounded-lg border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}>
            <div className="flex items-center">
              <span className="text-green-500 mr-2">🔑</span>
              <div>
                <h3 className="font-semibold">Password changed</h3>
                <p className="text-sm text-gray-500">2023-07-15 14:30</p>
              </div>
            </div>
          </div>
          <div className={`p-4 rounded-lg border ${darkMode ? 'bg-theme-dark-bg-to border-theme-border' : 'bg-white'}`}>
            <div className="flex items-center">
              <span className="text-blue-500 mr-2">📱</span>
              <div>
                <h3 className="font-semibold">New device added</h3>
                <p className="text-sm text-gray-500">2023-07-15 14:30</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;