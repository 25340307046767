export const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };
  
  export const formatNumber = (number: number, decimals: number = 4): string => {
    return number.toFixed(decimals);
  };
  
  export const formatAddress = (address: string, chars: number = 4): string => {
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };