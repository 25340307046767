import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { FiUpload,FiDownload, FiCopy, FiExternalLink, FiEye, FiEyeOff, FiArrowUp, FiArrowDown, FiChevronDown, FiPlus, FiTrash2, FiRefreshCw  } from 'react-icons/fi';
import WalletList from './WalletList';
import TokenList from './TokenList';
import TokenOverview from './TokenOverview';
import { formatCurrency } from '../../utils/formatters';
import { Account } from "./types";
import { Toaster, toast } from 'sonner';
import {
  getWalletInfoFromMnemonic,
  shortenAddress,
  getWalletBalance,
} from "../../Components/NewChatWindows/walletUtils";
// Define types for our data structures
interface Wallet {
  id: string;
  name: string;
  address: string;
  totalBalance: number;
  tokens: Token[];
}

interface Token {
  id: string;
  name: string;
  symbol: string;
  icon: string;
  balance: number;
  price: number;
  priceChange24h: number;
  priceHistory: { date: string; price: number }[];
}

const WalletPage: React.FC = () =>{
  const { colors } = useTheme();
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const { darkMode } = useTheme();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [defaultAccountIndex, setDefaultAccountIndex] = useState<number>(0);
  const [isPrivateMode, setIsPrivateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [importMnemonic, setImportMnemonic] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportMnemonic, setExportMnemonic] = useState("");

  const username = localStorage.getItem("walletname") || "User";

  const theme = {
    bg: darkMode ? '#141718' : '#F8F9FA',
    cardBg: darkMode ? '#232627' : '#FFFFFF',
    text: darkMode ? '#FFFFFF' : '#000000',
    secondaryText: darkMode ? '#A0A0A0' : '#6C757D',
    accent: '#00FFF2',
    green: '#00FF83',
    border: darkMode ? '#2D3235' : '#E9ECEF',
  };


  // Simulated data - replace with actual API calls in production
  useEffect(() => {
    // Simulated API call to fetch wallets
    const fetchedWallets: Wallet[] = [
      {
        id: '1',
        name: 'Main Wallet',
        address: '0x1234...5678',
        totalBalance: 5000,
        tokens: [
          { 
            id: '1',
            name: 'Ethereum', 
            symbol: 'ETH', 
            icon: '/path-to-ethereum-icon.svg', 
            balance: 1.5, 
            price: 2000,
            priceChange24h: 2.5,
            priceHistory: [
              { date: '2023-07-01', price: 1950 },
              { date: '2023-07-02', price: 1980 },
              { date: '2023-07-03', price: 2000 },
            ]
          },
          { 
            id: '2',
            name: 'Binance Coin', 
            symbol: 'BNB', 
            icon: '/path-to-bnb-icon.svg', 
            balance: 10, 
            price: 300,
            priceChange24h: -1.2,
            priceHistory: [
              { date: '2023-07-01', price: 305 },
              { date: '2023-07-02', price: 302 },
              { date: '2023-07-03', price: 300 },
            ]
          },
          { 
            id: '3',
            name: 'Polygon', 
            symbol: 'MATIC', 
            icon: '/path-to-matic-icon.svg', 
            balance: 1000, 
            price: 0.75,
            priceChange24h: 0.8,
            priceHistory: [
              { date: '2023-07-01', price: 0.74 },
              { date: '2023-07-02', price: 0.745 },
              { date: '2023-07-03', price: 0.75 },
            ]
          },
        ]
      },
      // Add more wallets here
    ];

    setWallets(fetchedWallets);
    // setSelectedWallet(fetchedWallets[0]);
  }, []);

  const loadWallets = useCallback(async () => {
    setIsLoading(true);
    try {
      const storedAccounts = localStorage.getItem("accounts");
      if (storedAccounts) {
        const parsedAccounts = JSON.parse(storedAccounts);
        const updatedAccounts = await Promise.all(
          parsedAccounts.map(async (account: Account) => {
            const balance = await getWalletBalance(account.address, account.network);
            return { ...account, balance };
          })
        );
        setAccounts(updatedAccounts);
        const storedDefaultIndex = localStorage.getItem("defaultAccountIndex");
        const defaultIndex = storedDefaultIndex ? parseInt(storedDefaultIndex, 10) : 0;
        setDefaultAccountIndex(defaultIndex);

        // Update local storage with the current default account
        updateLocalStorageWithDefaultAccount(updatedAccounts[defaultIndex]);
      } else {
        setAccounts([]);
        toast.error("No accounts found. Please create a new wallet.");
      }
    } catch (error) {
      console.error("Error loading wallets:", error);
      toast.error("Failed to load wallets. Please try again.");
      setAccounts([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  const updateLocalStorageWithDefaultAccount = (account: Account) => {
    localStorage.setItem("mnemonic", account.mnemonic);
    localStorage.setItem("account", account.address);
    localStorage.setItem("balance", account.balance.toString());
  };

  useEffect(() => {
    // Set the default selected token to the first token in the list
    if (tokens.length > 0 && !selectedToken) {
      setSelectedToken(tokens[0]);
    }
  }, [tokens, selectedToken]);

  const handleImportWallet = async () => {
    if (!importMnemonic.trim()) {
      toast.error("Please enter a valid mnemonic");
      return;
    }

    setIsLoading(true);
    try {
      const newWalletName = `Imported Wallet ${accounts.length + 1}`;
      const newWallet = await getWalletInfoFromMnemonic(importMnemonic, newWalletName);
      const updatedAccounts = [...accounts, ...newWallet];
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      const newIndex = updatedAccounts.length - 1;
      setDefaultAccountIndex(newIndex);
      localStorage.setItem("defaultAccountIndex", newIndex.toString());
      toast.success("Wallet imported successfully and set as default");
      setShowImportModal(false);
      setImportMnemonic("");
    } catch (error) {
      console.error("Error importing wallet:", error);
      toast.error("Failed to import wallet. Please check your mnemonic and try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetDefaultAccount = (index: number) => {
    setDefaultAccountIndex(index);
    localStorage.setItem("defaultAccountIndex", index.toString());
    const selectedAccount = accounts[index];
   updateLocalStorageWithDefaultAccount(selectedAccount);
    toast.success(`Set ${accounts[index].name} as default account`);
  };

  const handleDeleteAccount = (index: number) => {
    if (accounts.length <= 1) {
      toast.error("Cannot delete the only account");
      return;
    }

    const updatedAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(updatedAccounts);
    localStorage.setItem("accounts", JSON.stringify(updatedAccounts));

    if (index === defaultAccountIndex) {
      const newDefaultIndex = index > 0 ? index - 1 : 0;
      setDefaultAccountIndex(newDefaultIndex);
      localStorage.setItem("defaultAccountIndex", newDefaultIndex.toString());
    } else if (defaultAccountIndex > index) {
      setDefaultAccountIndex(defaultAccountIndex - 1);
      localStorage.setItem("defaultAccountIndex", (defaultAccountIndex - 1).toString());
    }

    toast.success("Account deleted successfully");
  };

  const togglePrivateMode = () => {
    setIsPrivateMode(!isPrivateMode);
  };

  const refreshBalances = async () => {
    setIsLoading(true);
    try {
      const updatedAccounts = await Promise.all(
        accounts.map(async (account) => {
          const balance = await getWalletBalance(account.address, account.network);
          return { ...account, balance };
        })
      );
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      toast.success("Balances refreshed successfully");
    } catch (error) {
      console.error("Error refreshing balances:", error);
      toast.error("Failed to refresh balances. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportWallet = (index: number) => {
    setExportMnemonic(accounts[index].mnemonic);
    setShowExportModal(true);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const handleWalletSelect = (wallet: Wallet) => {
    // setSelectedWallet(wallet);
    setSelectedToken(null);
  };

  const handleTokenSelect = (token: Token) => {
    setSelectedToken(token);
  };

  const handleCreateWallet = () => {
    // Implement wallet creation logic
    console.log('Create new wallet');
  };

  return (
    <div className="min-h-screen " style={{ backgroundColor: colors.bg, color: colors.text }}>
      <div className="container mx-auto px-7 py-8">
      <h1 className="text-4xl font-bold mb-8">Wallet</h1>
      {/* Navigation Tabs */}
      <nav className="flex space-x-8 " >
        {['🖥️ Overview', '🗳️ Staking', '🪙 Tokens', '🧾 Transaction History'].map((tab) => (
          <button
            key={tab}
            className={`pb-2 text-xl font-bold ${
              selectedTab === tab
                ? 'border-b-2 text-teal-500  border-teal-500'
                : 'text-gray-500'
            }`}
            style={{ borderColor: selectedTab === tab ? colors.accent : 'transparent' }}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </nav>
      </div>

      {/* Portfolio Overview */}
      <div className="mb-8 p-6 py-4 bg-theme-border" >
        <h2 className="text-lg font-black mb-2" style={{ color: colors.bg }}>Portfolio</h2>
        <h3 className="text-lg font-medium" style={{ color: colors.bg }}>TOTAL BALANCE</h3>
        <p className="text-4xl font-extrabold" style={{ color: colors.bg }}>{formatCurrency(totalBalance)}</p>
      </div>

      {/* Wallet Manager */}
      <div className="md:flex mb-8 p-6 py-4 border-b border-t border-theme-border ">
        <div>
        <h2 className="text-2xl font-bold mb-4">Wallet Manager</h2>
        <p className="text-secondary-text mb-4">View and manage your wallets</p>

        </div>

        <div>
          <div className="flex space-x-4 overflow-x-auto pb-4">
          {tokens.map((token) => (
            <button
              key={token.symbol}
              className={`flex items-center space-x-2 p-3 rounded-xl ${
                selectedToken?.symbol === token.symbol ? 'ring-2' : ''
              }`}
              style={{ 
                backgroundColor: colors.cardBg, 
                border: `1px solid ${colors.border}`,
                // ringColor: colors.accent
              }}
              onClick={() => handleTokenSelect(token)}
            >
              <img src={token.icon} alt={token.name} className="w-6 h-6" />
              
              <span>{token.balance} {token.symbol} </span>
            </button>
          ))}
        </div>
        </div>
       
        
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* <WalletList wallets={wallets} theme={colors} />
        {selectedToken && <TokenOverview selectedToken={selectedToken} theme={colors} />} */}
      </div>

      <div className="" >
      <Toaster richColors closeButton position="top-center" />
      <div className="container mx-auto px-6 py-1">
        {/* Header */}
        
        {/* Navigation Tabs */}
       
        {/* Main Content */}
        <main>
          {/* Action Buttons */}
          <div className="grid grid-cols-3 gap-4 mb-8">
            {/* <button
              className="py-3 px-4 rounded-lg font-medium text-sm"
              style={{ backgroundColor: theme.accent, color: theme.bg }}
              onClick={handleQuickCreateWallet}
            >
              Quick Create
            </button> */}
            <button
              className="py-3 px-4 rounded-lg font-medium text-sm"
              style={{ backgroundColor: theme.accent, color: theme.bg }}
              onClick={() => setShowImportModal(true)}
            >
              Import
            </button>
            <button
              className="py-3 px-4 rounded-lg font-medium text-sm"
              style={{ backgroundColor: theme.accent, color: theme.bg }}
              onClick={togglePrivateMode}
            >
              {isPrivateMode ? "Show" : "Hide"} Balance
            </button>
            <button className="p-2 rounded-full max-w-9" style={{ backgroundColor: theme.cardBg }} onClick={refreshBalances}>
              <FiRefreshCw className={`w-5 h-5 ${isLoading ? "animate-spin" : ""}`} />
            </button>
          </div>

          {/* Wallet Info Card */}
          <div className="p-6 rounded-xl mb-8" style={{ backgroundColor: theme.cardBg }}>
            <div className="flex justify-between items-center ">
              <div>
                <h2 className="text-lg font-mono mb-2">{shortenAddress(accounts[defaultAccountIndex]?.address || "")}</h2>
                <div className="flex items-center space-x-4">
                  <span className="text-3xl font-bold">
                    {isPrivateMode ? "******" : `$${(parseFloat(accounts[defaultAccountIndex]?.balance || "0") * 0.1).toFixed(2)}`}
                  </span>
                  <span className="text-xl" style={{ color: theme.secondaryText }}>
                    {isPrivateMode ? "******" : `${accounts[defaultAccountIndex]?.balance || "0"} SOL`}
                  </span>
                </div>
              </div>
              <div className="flex space-x-2">
                <button className="p-2 rounded-full" style={{ backgroundColor: theme.bg }} onClick={() => copyToClipboard(accounts[defaultAccountIndex]?.address || "")}>
                  <FiCopy className="w-5 h-5" />
                </button>
                <button className="p-2 rounded-full" style={{ backgroundColor: theme.bg }} onClick={() => handleExportWallet(defaultAccountIndex)}>
                  <FiDownload className="w-5 h-5" />
                </button>
                <button className="p-2 rounded-full" style={{ backgroundColor: theme.bg }}>
                  <FiExternalLink className="w-5 h-5" />
                </button>
                
              </div>
              <div className="text-right">
              <span className="px-3 py-1 rounded-full text-xs font-medium" style={{ backgroundColor: theme.green, color: theme.bg }}>
                Active
              </span>
            </div>
          </div>
            </div>
            

          {/* Accounts List */}
          <div className="rounded-xl p-6 mb-8" style={{ backgroundColor: theme.cardBg }}>
            <h3 className="text-xl font-semibold mb-6">Your Accounts</h3>
            <div className="space-y-4">
              {accounts.map((account, index) => (
                <div key={index} className="flex justify-between items-center p-4 rounded-lg" style={{ backgroundColor: theme.bg }}>
                  <div>
                    <p className="font-medium">{account.name}</p>
                    <p className="text-sm" style={{ color: theme.secondaryText }}>{shortenAddress(account.address)}</p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-sm font-medium">
                      {isPrivateMode ? "******" : `${account.balance} SOL`}
                    </span>
                    {index === defaultAccountIndex ? (
                      <span className="text-xs px-2 py-1 rounded-full" style={{ backgroundColor: theme.green, color: theme.bg }}>Default</span>
                    ) : (
                      <button onClick={() => handleSetDefaultAccount(index)} className="text-xs px-2 py-1 rounded-full" style={{ backgroundColor: theme.accent, color: theme.bg }}>Set Default</button>
                    )}
                    <button onClick={() => handleDeleteAccount(index)} className="text-red-500">
                      <FiTrash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>

      {/* Import Modal */}
      {/* Import Modal */}
      {showImportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Import Wallet</h2>
            <textarea
              value={importMnemonic}
              onChange={(e) => setImportMnemonic(e.target.value)}
              placeholder="Enter wallet mnemonic"
              className="w-full p-2 border border-gray-300 rounded-md mb-4 h-32"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowImportModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleImportWallet}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Import Wallet
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Export Modal */}
      {showExportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-2xl font-semibold mb-4">Export Wallet</h2>
            <p className="text-sm text-gray-600 mb-2">Your mnemonic phrase:</p>
            <div className="bg-gray-100 p-3 rounded-md mb-4 break-all">
              {exportMnemonic}
            </div>
            <div className="flex justify-between items-center mb-4">
              <p className="text-sm text-red-500">
                Keep this phrase safe and secret!
              </p>
              <button
                onClick={() => copyToClipboard(exportMnemonic)}
                className="flex items-center text-blue-500 hover:text-blue-600"
              >
                <FiCopy className="w-4 h-4 mr-1" /> Copy
              </button>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowExportModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default WalletPage;